import "./WithdrawFarm.styles.css"

type props = {
    colors: any
    poolName: string
    stateEvent: number
    setStateEvent: React.Dispatch<React.SetStateAction<number>>
    poolToken: string
}

export const WithdrawFarm = ({colors, poolName}: props) => {
    return (
        <div className="withdraw-farm-container">
            <h3 style={{color: colors.text}}><span>YOU ARE FARMING</span> 50 {poolName}</h3>
            <input style={{color: colors.text, backgroundColor: colors.neutralBlack}} placeholder="0.0" type={"numder"}/>
            <div className="withdraw-farm-manipulating">
                <button style={{color: colors.text}} className="max">MAX</button>
                <button className="farm-btn">WITHDRAW</button>
            </div>
        </div>
    )
}