import React, { useRef } from "react"

import "./PoolFee.styles.css"

type props = {
    colors: any
    inputValue: React.Dispatch<React.SetStateAction<string>>
    tokenImg: string
    tokenName: string
    userTokenBalance: string
    isRemovePage?: boolean
}

export const PoolFee = ({ colors, inputValue, tokenImg, tokenName, userTokenBalance, isRemovePage }: props) => {
    const inputToken = useRef<HTMLInputElement>(null)

    const correctInput = (e: React.FormEvent<HTMLInputElement>) => {
        const inputVal = e.currentTarget.value
        if (isRemovePage === true) {
            if (Number(inputVal) > Number(userTokenBalance)) {
                e.currentTarget.value = userTokenBalance
            }
            if (Number(inputVal) < 0) {
                e.currentTarget.value = "0"
            }
            
            if(inputVal.indexOf(".") !== -1) {
                if (inputVal.split('.')[1].length > 18) {
                    const val = inputVal.split('.')[0] + "." + inputVal.split('.')[1].slice(0, -1)
                    e.currentTarget.value = val
                }
            }
        }
        inputValue(e.currentTarget.value)
    }
    
    return (
        <>
            <section
                className="pool-fee-balance"
                style={{color: colors.text, transition: ".5s"}}
            ><span>Balance:</span> {userTokenBalance}</section>
            <div className="input-box-poolfee">
                <input ref={inputToken} onChange={e => {correctInput(e)}} style={{
                    backgroundColor: colors.neutralBlack,
                    color: colors.text
                }} placeholder={"0.0"}
                    type="number"/>
                <div className="btnSelectFeeToken" style={{backgroundColor: colors.neutralBlack}}>
                    <button style={{cursor: "default"}}>
                        <img className="feeTokenLogo" src={tokenImg} alt="token"/>
                        <span>{tokenName}</span>
                    </button>
                </div>
            </div>
            {isRemovePage ? (
                <div className="max-btn-container">
                    <button style={{color: colors.text}} onClick={() => {inputValue(userTokenBalance); inputToken.current!.value = userTokenBalance}}>MAX</button>
                </div>
            ) : ""}
        </>
    )
}