import { useNavigate } from "react-router-dom"
import "./Menu.styles.css"

type props = {
    colors: any
    active: boolean
}

export const Menu = ({active, colors}: props) => {
    let navigate = useNavigate()
    return (
        <div style={{backgroundColor: colors.lightBlack}} className="menu-container">
            <div className="menu-group">
                {active ? (<button className="menuActive" style={{backgroundColor: colors.menuActive, color: colors.text}}>SWAP</button>) : (<button onClick={() => {navigate('/')}} style={{color: colors.text}}>SWAP</button>)}
                {active ? (<button onClick={() => {navigate('/pools')}} style={{color: colors.text}}>POOLS</button>) : (<button className="menuActive" style={{backgroundColor: colors.menuActive, color: colors.text}}>POOLS</button>)}
            </div>
            <div className="menu-group">
                <button style={{color: colors.text}}>EVENTS</button>
                <button style={{color: colors.text}}>LAUNCHPAD</button>
            </div>
        </div>
    )
}