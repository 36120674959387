import React, { MouseEventHandler , useState } from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faTelegram, faYoutube, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { Menu } from "../components/HeaderMenu/Menu"
import { News } from "../components/News/News"
import { NetworkManipulations } from "../components/Network/Network"
import { getBlock } from "../eth"

import "./Layout.styles.css"

type props = {
    title: string
    children: React.ReactNode | string
    colors: any
    themeModificator: MouseEventHandler
    isLogined: boolean
    setLogin: any
    setLoginedClass: any
    loginEvent: any
    logoutEvent: any
    menuActive: boolean
}

export const Layout = ({title, children, colors, themeModificator, isLogined, setLogin, setLoginedClass, loginEvent, logoutEvent, menuActive}: props) => {
    const [block, setBlock] = useState<number>()

    setTimeout(async () => {
        const num = await getBlock()
        setBlock(num)
    }, 1000)
    
    return (
        <div style={{height: "100%"}}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div style={{color: colors.text}} className="header">
                <div className="logo-container">
                    <img src="/logo.png" alt="logo"/>
                    <div className="logo-text">
                        <h1>LOVELY SWAP</h1>
                        <h3>ZERO-FEE SWAP</h3>
                    </div>
                </div>
                <div className="menu-com">
                    <div className={`menu menu-${isLogined}`}>
                        <Menu active={menuActive} colors={colors}/>
                    </div>
                    <NetworkManipulations colors={colors} themeModificator={themeModificator} isLogined={isLogined} loginEvent={loginEvent} logoutEvent={logoutEvent} />
                </div>
                <div style={{color: colors.grey, backgroundColor: colors.lightBlack}} className="head-adv"><p>ADV</p></div>
            </div>
            <div className="content">{children}</div>
            <div className="footer">
                <div className="f-links-center">
                    <div className="social-row">
                        <a href="https://t.me/lovelyinu_coin" target={"_blank"} className="social" rel="noreferrer">
                            <span>Telegram</span>
                            <FontAwesomeIcon icon={faTelegram} />
                        </a>
                        <a href="https://youtube.com/channel/UC8zILmP8leSpFqOnUdFIpUQ" target={"_blank"} className="social" rel="noreferrer">
                            <span>YouTube</span>
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://twitter.com/Lovely_finance?s=09" target={"_blank"} className="social" rel="noreferrer">
                            <span>Twitter</span>
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </div>
                    <div className="social-row">
                        <a href="https://instagram.com/lovely_inu" target={"_blank"} className="social" rel="noreferrer">
                            <span>Instagram</span>
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://github.com/LovelyFinance/Lovely-Inu-Finance" target={"_blank"} className="social" rel="noreferrer">
                            <span>GitHub</span>
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                        <a href="https://google.com/" target={"_blank"} className="social" rel="noreferrer">
                            <span>Whitepaper</span>
                            <FontAwesomeIcon icon={faFile} />
                        </a>
                    </div>
                </div>
                <a href="#" className="blocks" style={{color: "green", zIndex: 3}}>{block}</a>
            </div>
            <News />
        </div>
    )
}