import Web3 from "web3";
import { provider } from ".";
import { getContract, getERC20Contract } from "./ContractController"

class PoolController {
    web3 = new Web3(provider)

    async getPool (tokenA: string, tokenB: string) {
        const factory = await getContract("LOVELYFactory")
        const poolAddress = await factory.getPair(tokenA, tokenB).call()
        return poolAddress
    }

    async getUserLiquidityAmount (poolAddress: string, userAccountAddress: string): Promise<string> {
        const contract = await getERC20Contract(poolAddress)
        const liquidityAmount = await contract.balanceOf(userAccountAddress).call()
        return await liquidityAmount
    }

    async getPoolFee (address: string) {
        const contract = await getContract("LOVELYPairToken", address)
        return contract.getFee().call()
    }
}

const poolController = new PoolController()

export const getPool = async (tokenA: string, tokenB: string) => {return await poolController.getPool(tokenA, tokenB)}
export const getUserLiquidityAmount = async (poolAddress: string, userAccountAddress:string) => {return await poolController.getUserLiquidityAmount(poolAddress, userAccountAddress)}
export const getPoolFee = async (address: string) => {return await poolController.getPoolFee(address)}