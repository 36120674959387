import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

export const ArrowDown = ({ colors }: any) => {
    return (
        <div style={{
            backgroundColor: colors.neutralBlack,
            width: 40,
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 13,
            transition: "background-color .5s"
        }}>
            <div style={{
                backgroundColor: colors.darkGrey,
                width: 36,
                height: 36,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 12,
                transition: "background-color .5s"
            }}>
                <FontAwesomeIcon icon={faArrowDown}/>
            </div>
        </div>
    )
}