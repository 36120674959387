import { useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import Web3 from "web3";
import { approveToken, getContract, getSymbol, getContractAddress } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { provider } from "../../../eth";

import './TokenActivate.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function TokenActivate ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [amount, setAmount] = useState<string>("")
  const [token, setToken] = useState<string>("")
  const [feeToken, setFeeToken] = useState<string>("")
  const [feeTokenName, setFeeTokenName] = useState<string>("")
  const web3 = new Web3(provider)

  useEffect(() => {
    const load = async () => {
      const contract = await getContract("LOVELYTokenList")
      const token = location.pathname.split("/")[3]
      setToken(token)

      const id = location.search.slice(1, location.search.length).split("=")[1]
      let amount: string = await contract.feeAt(id).call()
      let feeToken: string = await contract.tokenAt(id).call()
      let feeTokenName: string = await getSymbol(feeToken)
      setAmount(amount)
      setFeeToken(feeToken)
      setFeeTokenName(feeTokenName)
      setStateEvent(stateEvent+1)
      /*setTimeout(() => {
        setStateEvent(stateEvent+1)
      }, 250)*/
    }
    load()
  }, [])

  const pay = async () => {
    setStateEvent(stateEvent+1)
    const contract = await getContract("LOVELYTokenList")
    let error = false

    try {
      const tokeListAddress: string = await getContractAddress("LOVELYTokenList")
      const approve = await approveToken(feeToken, tokeListAddress, String(localStorage.getItem("__account")), amount.toString())
      error = false
    } catch (e) {
      alert("Error: Not approved!!!")
      console.log(e)
      setStateEvent(1)
      error = true
    }

    if (!error) {
      try {
        const tokenActivatation = await contract.validate(token).send({from: localStorage.getItem("__account")})
        error = false
      } catch (e) {
        alert("Error: Not validated!!!")
        console.log(e)
        setStateEvent(1)
        error = true
      }
    }

    if (!error) {setStateEvent(stateEvent+1); rerunSchedule(); setTimeout(() => {navigate(-1)}, 1500)}
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-activate">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-activate-heading">
          {stateEvent === 0 ? (
            <>
              <h1>Loading</h1>
            </>
          ) : (
            stateEvent === 1 ? (
              <>
                <FontAwesomeIcon className='token-activate-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Token Activation</h1>
                  <span style={{fontSize: 14}}>Activation Fee</span>
                </div>
              </>
            ) : (
              stateEvent === 2 ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Token Activation</h1>
                  <span style={{fontSize: 14}}>Awaiting</span>
                </div>
              ) : (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Token Activation</h1>
                  <span style={{fontSize: 14}}>Done</span>
                </div>
              ))
          )}
        </div>
        <div className={`token-add-body active-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="token-activate-body-box">
              <Loader load={true} />
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="token-fee-body-box">
                <span style={{fontSize: 16}}>Pay Token Validation Fee:</span>
                <h3>{web3.utils.fromWei(amount.toString())} {feeTokenName}</h3>
              </div>
            ) : (
              stateEvent === 2 ? (
                <div className="token-activate-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                <div className="token-activate-body-box">
                  <Loader load={false} />
                </div>
              )
            )
          )}
        </div>
        <div className={`token-activate-footer active-footer-${stateEvent}`}>
          {stateEvent === 1 ? (
            <button onClick={pay}>ACTIVATE</button>
          ) : ("")}
        </div>
      </div>
    </Layout>
  );
}

export default TokenActivate;