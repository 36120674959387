import React, { MouseEventHandler } from "react";
import { Routes, Route } from "react-router-dom"
import App from './pages/Index/App';
import Pools from './pages/Pools/Pools';
import PoolsAdd from './pages/Pools/AddPool/PoolAdd';
import PoolsLiquidity from './pages/Pools/AddLiquidity/PoolLiquidity';
import RemoveLiquidity from './pages/Pools/RemoveLiquidity/RemoveLiquidity';
import TokenAdd from './pages/Token/Add/TokenAdd';
import TokenActivate from './pages/Token/Activate/TokenActivate';
import Farms from "./pages/Farm/Farms";
import FarmAdd from "./pages/Farm/FarmAdd/FarmAdd";
import FarmSettings from "./pages/Farm/FarmSettings/FarmSettings";

type props = {
    theme: any
    themeSwitcher: MouseEventHandler
    isLogined: boolean
    setLogin: React.Dispatch<React.SetStateAction<boolean>>
    isLoginedClass: string
    setLoginedClass: React.Dispatch<React.SetStateAction<string>>
    loginEvent: () => Promise<void>
    logoutEvent: () => Promise<void>
    tokenList: any
    setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

export const Navigation = ({theme, themeSwitcher, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent, tokenList, setTokenList}: props) => {
    return (
        <Routes>
            <Route path='/' element={<App 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
            tokenList={tokenList}
            setTokenList={setTokenList}
          />}
        />
        <Route path='/pools' element={<Pools 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/pools/add' element={<PoolsAdd 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
            tokenList={tokenList}
            setTokenList={setTokenList}
          />}
        />
        <Route path='/pools/liquidity' element={<PoolsLiquidity 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/pools/remove_liquidity' element={<RemoveLiquidity 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/token/add' element={<TokenAdd 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/token/activate/:token' element={<TokenActivate
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/farms' element={<Farms 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        <Route path='/farms/add' element={<FarmAdd 
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
            tokenList={tokenList}
            setTokenList={setTokenList}
          />}
        />
        <Route path='/farm/settings' element={<FarmSettings
            colors={theme}
            themeModificator={themeSwitcher}
            isLogined={isLogined}
            setLogin={setLogin}
            isLoginedClass={isLoginedClass}
            setLoginedClass={setLoginedClass}
            loginEvent={loginEvent}
            logoutEvent={logoutEvent}
          />}
        />
        </Routes>
    )
}