import React, { RefObject, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { InputModal } from "../Modals/InputModal/InputModal"

import "./SwapInput.styles.css"

type props = {
    colors: any
    token: string
    setToken: React.Dispatch<React.SetStateAction<string>>
    onChange: (num: string) => void
    inputRef: RefObject<HTMLInputElement>
    tokenList: any[]
    setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

export const SwapInput = ({ colors, token, setToken, onChange, inputRef, tokenList, setTokenList}: props) => {
    const [show, setShow] = useState<boolean>(false)
    const [tokenName, setTokenName] = useState<string>("")
    const [tokenImg, setTokenImg] = useState<string>("")
    let input: string;
    let typingTimer: any;

    const stateChangeEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {onChange(input)}, 1500)
    }
    //value={token.length !== 0 ? exValue : ""}
    return (
        <div className="input-box">
            {show ? (<InputModal setShow={setShow} colors={colors} setToken={setToken} setTokenName={setTokenName} setTokenImg={setTokenImg} tokenList={tokenList} setTokenList={setTokenList} />) : null}
            <input 
                ref={inputRef}
                onKeyUp={(e) => {input = e.currentTarget.value; stateChangeEvent(e)}}
                onKeyDown={() => {clearTimeout(typingTimer)}}
                min={0}
                style={{
                    backgroundColor: colors.neutralBlack,
                    color: colors.text
                }} type={"number"} placeholder={"0.0"}/>
            <div className="btnSelectToken" style={{backgroundColor: colors.neutralBlack}}>
                <button onClick={() => {setShow(!show)}}>
                    {token.length === 0 ? (
                        <>
                            SELECT A TOKEN
                            <FontAwesomeIcon style={{marginLeft: 20}} icon={faChevronDown} />
                        </>
                    ) : (
                        <>
                            <img src={tokenImg} alt="coin"/>
                            {tokenName}
                        </>
                    )}
                </button>
            </div>
        </div>
    )
}