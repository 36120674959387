import { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { Layout } from '../Layout';
import { isFarmListLoading, farmList } from '../../eth/ListController';
import { FarmItem } from '../../components/FarmItem/FarmItem';
import './Farms.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function Farms({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  let navigate = useNavigate()
  const [farms, setFarms] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const load = async () => {
      setFarms(farmList)
      setIsLoading(isFarmListLoading)
    }
    setInterval(() => {
      load()
    }, 100)
  }, [])

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div className="farms-frame">
        <div className="farms-header">
          <div className="farms-header-text">
            <h1 style={{color: colors.text}}>FARMING POOLS OVERVIEW</h1>
            <p style={{color: colors.grey}}>Add liquidity tokens in farms</p>
          </div>
          <div className="farms-header-manip">
            <button onClick={() => {navigate('/farms/add')}}>CREATE FARMING POOL</button>
          </div>
        </div>
        <div style={{color: colors.grey, backgroundColor: colors.lightBlack}} className="pools-adv">
          <p>ADV</p>
        </div>
        <div style={{color: colors.grey, backgroundColor: colors.neutralBlack}} className="pools-overview-box">
          {isLoading ? (
              <div>
                <Loader load={true} />
              </div>
            ) : (
              farms.length === 0 ? (
                <div className='farms-emty'>
                  <img src='/icons/inbox_icon.png' alt='inbox' />
                  <p>NO FARMING POOLS FOUND.<br/>NEW POSITIONS WILL APPEAR HERE.</p>
                </div>
              ) : (
                <div>
                  {farms.map((item) => (
                    <FarmItem key={item.id} colors={colors} poolItem={item}/>
                  ))}
                </div>
              )
            )}
        </div>
      </div>
    </Layout>
  );
}

export default Farms;
