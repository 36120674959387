import { getContract } from "./ContractController"
import { setFarmList, poolList } from "./ListController"

class FarmController {   

    async createFarmingPool (pairToken: string, userAccountAddress: string, revardAmount: string, expDate: string) {
        const farmContract = await getContract("LOVELYFarm")
        return await farmContract.createPool(pairToken, userAccountAddress, userAccountAddress, revardAmount, Date.now(), expDate).send({from: userAccountAddress})
    }

    async addPairInFarm (pairToken: string, userAccountAddress: string) {
        const farmContract = await getContract("LOVELYFarm")
        let lastCreatedFarm: string = await farmContract.poolCount().call()
        lastCreatedFarm = String(Number(lastCreatedFarm)-1)
        await farmContract.add(lastCreatedFarm, pairToken).send({from: userAccountAddress})
    }

    async getUserFarmBalance (poolAddress: string): Promise<string> {
        const farmContract = await getContract("LOVELYFarm")
        return await farmContract.liquidityPoolBalanceOfUser(poolAddress).call()
    }

    async getFarmById() {
        const farmContract = await getContract("LOVELYFarm")
        let finish:boolean = false
        let i: number = 0
        let pairTokens: any [] = []
        do {
            try {
                const pairAddress: string = await farmContract.at(i).call()
                if (pairAddress.indexOf("0x0000000000000000000000000000000000000000") === -1) {
                    const status = await farmContract.getStatus(0).call()
                    
                    pairTokens.push({
                        address: pairAddress,
                        status: status
                    })
                } else {
                    finish = true
                }
                i++
            } catch (e) {
                finish = true
            }
        } while (!finish)
        
        const farmList = pairTokens.map((item, i) => {
            let returnedObj = {
                id: i,
                name: "",
                token: item.address,
                status: item.status,
                tokenA: "",
                tokenB: "",
                imgA: "",
                imgB: "",
                amount: "0"
            }

            poolList.map((pool) => {
                if (pool.token === item.address) {
                    returnedObj.name = pool.name
                    returnedObj.tokenA = pool.tokenA
                    returnedObj.tokenB = pool.tokenB
                    returnedObj.imgA = pool.imgA
                    returnedObj.imgB = pool.imgB
                }
            })
            
            return returnedObj
        })

        setFarmList(farmList)
    }

    async depositTokensInFarm (poolIdentifier: string, amount: string, userAccountAddress: string) {
        const farmContract = await getContract("LOVELYFarm")
        await farmContract.deposit(poolIdentifier, amount).send({from: userAccountAddress})
    }
}

export const farmController = new FarmController()
export const createFarmingPool = async (pairToken: string, userAccountAddress: string, revardAmount: string, expDate: string) => {return await farmController.createFarmingPool(pairToken, userAccountAddress, revardAmount, expDate)}
export const addPairInFarm = async (pairToken: string, userAccountAddress: string) => {return await farmController.addPairInFarm(pairToken, userAccountAddress)}
export const getUserFarmBalance = async (poolAddress: string) => {return await farmController.getUserFarmBalance(poolAddress)}
export const getFarmById = async () => {return await farmController.getFarmById()}
export const depositTokensInFarm = async (poolIdentifier: string, amount: string, userAccountAddress: string) => {return await farmController.depositTokensInFarm(poolIdentifier, amount, userAccountAddress)}