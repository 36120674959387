import React, { useCallback, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import { PoolFee } from "../../../components/Inputs/PoolFee/PoolFee";
import { FarmTokenDropdown } from "../../../components/Dropdown/FarmTokenDropdown/FarmTokenDropdown";
import { approveToken, getContract, getContractAddress, getERC20Contract } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { addPairInFarm, createFarmingPool, getFarmById } from "../../../eth/FarmController";
import { provider } from "../../../eth";
import Web3 from "web3";

import './FarmAdd.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
  tokenList: any[]
  setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

function FarmAdd ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent, tokenList, setTokenList}: props) {
  const navigate = useNavigate()
  const correctInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const inputVal = e.currentTarget.value.replace(".", ",")
    if (Number(inputVal) > 100) {
      e.currentTarget.value = "100"
    }
    if (Number(inputVal) < 0.000000000000000001) {
      e.currentTarget.value = "0.000000000000000001"
    }
    
    if(inputVal.indexOf(",") === -1) {
      if (inputVal.length >= 4) {
        e.currentTarget.value = inputVal.slice(0, -1)
      }
    } else {
      if (inputVal.split(',')[0].length > 2) {
        e.currentTarget.value = inputVal.split(',')[0].slice(0, -1) + "." + inputVal.split(',')[1]
      }
      if (inputVal.split(',')[1].length > 1) {
        e.currentTarget.value = inputVal.split(',')[0] + "." + inputVal.split(',')[1].slice(0, -1)
      }
    }
  }, [])
  const checkExpDate = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const datetime = new Date(e.currentTarget!.value)
    setExpDate(String(datetime.valueOf()))
  }, [])
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [expDate, setExpDate] = useState<string>("")
  const [poolListState, setPoolListState] = useState<any>([])
  let skiped: boolean = false

  const [pairToken, setPairToken] = useState<string>("")
  const [revardAmount, setRevardAmount] = useState<string>("")
  const [pairFirstImg, setPairTokenImg] = useState<string>("")
  const [pairSecImg, setPairTokenSecImg] = useState<string>("")
  const [pairTokenName, setPairTokenName] = useState<string>("")
  const [tokenFirstUserBalance, setFirstUserBalance] = useState<string>("0")
  const web3 = new Web3(provider)

  useEffect(() => {
    if (pairToken.length === 0) {
      setFirstUserBalance("0")
    } else {
      const getBalance = async () => {
        const contract = getERC20Contract(pairToken)
        const balance: string = await contract.balanceOf(localStorage.getItem("__account")).call()
        setFirstUserBalance(web3.utils.fromWei(balance))
      }
      getBalance()
    }
    getFarmById()
  }, [pairToken])

  const addPair = async () => {
    if (pairToken.length === 0) {
      return alert("Pleace select first token")
    }
    setStateEvent(stateEvent + 1)
  }

  const addAmount = () => {
    if (revardAmount.length === 0) {
      return alert("Pleace input amount of token in form")
    }
    setStateEvent(stateEvent + 1)
  }

  const createFarm = (): void => {
    console.log(expDate)
    if (expDate.length === 0) {
      return alert("Pleace input expiration date of farm in form")
    }
    setStateEvent(stateEvent + 1)
    farmCreation()
  }

  const farmCreation = async (): Promise<void> => {
    let errors = false
    const userAccountAddress: string | null = String(localStorage.getItem("__account"))

    try {
      await createFarmingPool(pairToken, userAccountAddress, revardAmount, expDate)
      errors = false
    } catch (e) {
      errors = true
      console.log(e)
      alert("We cant create farming pool")
    }

    if (!errors) {
      try {
        await addPairInFarm(pairToken, userAccountAddress)
        errors = false
      } catch (e) {
        errors = true
        console.log(e)
        alert("We cant add farm token")
      }
    }

    //if we dont have arrors -> navigate on next page
    if (!errors) {setStateEvent(stateEvent+1); rerunSchedule(); setTimeout(() => {navigate(-1)}, 1500)} else {setStateEvent(0)}
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-add">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-add-heading">
          {stateEvent === 0 ? (
            <>
              <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
              <h1>Add Farming Pool</h1>
            </>
          ) : (stateEvent === 1 ? (
            <>
              <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {setStateEvent(stateEvent-1)}} icon={faArrowLeft} />
              <h1>{pairTokenName} Revard Amount</h1>
            </>
          ) : (
            stateEvent === 2 ? (
              <>
                <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {setStateEvent(stateEvent-1)}} icon={faArrowLeft} />
                <h1>Farm Expiration Date</h1>
              </>
            ) : (
              stateEvent === 3 ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Adding Farm</h1>
                  <span style={{fontSize: 14}}>Awaiting</span>
                </div>
              ) : (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Farm Successfully Created</h1>
                  <span style={{fontSize: 14}}>Done</span>
                </div>
              )
            )
          )
            )}
        </div>
        <div className={`pool-add-body pool-add-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="pool-add-body-box">
              <h3>CHOOSE A VALID LIQUIDITY POOL</h3>
              <div className="validation-pair-container">
                <FarmTokenDropdown colors={colors} setToken={setPairToken} tokenList={poolListState} setTokenList={setPoolListState} tokenName={pairTokenName} setTokenName={setPairTokenName} tokenImg={pairFirstImg} setTokenImg={setPairTokenImg} tokenSecImg={pairSecImg} setTokenSecImg={setPairTokenSecImg} userTokenBalance={tokenFirstUserBalance}/>
              </div>
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="pool-add-body-box">
                <h3>CHOOSE REVARD AMOUNT</h3>
                <input
                  onChange={(e) => {setRevardAmount(e.currentTarget.value)}}
                  style={{color: colors.text, backgroundColor: colors.neutralBlack}}
                  min={0}
                  max={100}
                  onInput={(e) => {correctInput(e)}}
                  type={"number"}
                  placeholder="0.0"/>
              </div>
            ) : (stateEvent === 2 ? (
              <div className="pool-add-body-box">
                <h3>CHOOSE EXPIRATION DATE</h3>
                <input
                  onChange={(e) => {checkExpDate(e)}}
                  style={{color: colors.text, backgroundColor: colors.neutralBlack}}
                  type={"datetime-local"}/>
              </div>
            ) : (
              stateEvent === 3 ? (
                <div className="pool-add-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                <div className="pool-add-body-box">
                  <Loader load={false} />
                </div>
              )
            ))
            )}
        </div>
        <div className={`pool-add-footer pool-add-footer-${stateEvent}`}>
          {stateEvent === 0 ? (
            <button onClick={addPair}>NEXT</button>
          ) : (stateEvent === 1 ? (
            <button onClick={addAmount}>NEXT</button>
          ) : (
              <button onClick={createFarm}>CREATE FARM</button>
            ))}
        </div>
      </div>
    </Layout>
  );
}

export default FarmAdd;