import React, {useCallback, useRef, useState} from "react";
import { MouseEventHandler } from 'react';
import { Layout } from '../Layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { SwapInput } from '../../components/Inputs/SwapInput';
import { ArrowDown } from '../../components/ArrowDown/ArrowDown';
import { approveToken, getContract, getContractAddress } from "../../eth/ContractController";
import { getPool, getPoolFee } from "../../eth/PoolController";
import { provider } from "../../eth";
import Web3 from "web3";

import './App.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
  tokenList: any[]
  setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

function App({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent, tokenList, setTokenList}: props) {
  const [inputFirstToken, setInputFirstToken] = useState<string>("")
  const inputFirst = useRef<HTMLInputElement>(null)
  const [inputSecToken, setInputSecToken] = useState<string>("")
  const inputSec = useRef<HTMLInputElement>(null)
  const web3 = new Web3(provider)

  const onChangeFirstInput = useCallback(async (num: string) => {
    if (inputSec !== null) {
      if (inputFirst.current!.value.length > 0) {
        const contract = await getContract("LOVELYRouter")
        if (inputFirstToken.length !== 0 && inputSecToken.length !== 0) {
          let fee: string = ""
          let errors: boolean = false
          
          try {
            const pairAddress = await getPool(inputFirstToken, inputSecToken)
            fee = await getPoolFee(pairAddress)
            errors = false
          } catch (e) {
            alert("We cant get fee for that pool. Is it exist?")
            console.log(e)
            errors = true
          }

          if (!errors) {
            try {
              const amountOut: string[] = await contract.getAmountsOut(web3.utils.toWei(num), [inputFirstToken, inputSecToken], fee).call()
              inputSec.current!.value = web3.utils.fromWei(amountOut[1])
            } catch (e) {
              alert("We may not have the liquidity in the pool to get the price for the second token.")
              console.log(e)
            }
          }
        }
      } else {
        inputSec.current!.value = ""
      }
    }
  }, [inputFirstToken, inputSecToken])

  const onChangeSecondInput = useCallback(async (num: string) => {
    if (inputFirst !== null) {
      if (inputSec.current!.value.length > 0) {
        const contract = await getContract("LOVELYRouter")
        if (inputFirstToken.length !== 0 && inputSecToken.length !== 0) {
          let fee: string = ""
          let errors: boolean = false
          
          try {
            const pairAddress = await getPool(inputFirstToken, inputSecToken)
            fee = await getPoolFee(pairAddress)
            errors = false
          } catch (e) {
            alert("We cant get fee for that pool. Is it exist?")
            console.log(e)
            errors = true
          }

          if (!errors) {
            try {
              const amountIn: string[] = await contract.getAmountsIn(web3.utils.toWei(num), [inputSecToken, inputFirstToken], fee).call()
              inputFirst.current!.value = web3.utils.fromWei(amountIn[0])
            } catch (e) {
              alert("We may not have the liquidity in the pool to get the price for the first token.")
              console.log(e)
            }
          }
        }
      } else {
        inputFirst.current!.value = ""
      }
    }
  }, [inputFirstToken, inputSecToken])

  const swapAction = async () => {
    if (!isLogined) {
      loginEvent()
    } else {
      if (inputFirstToken.length !== 0 && inputSecToken.length !== 0 && inputFirst.current!.value.length !== 0 && inputSec.current!.value.length !== 0) {
        let errors: boolean = false
        
        const contract = await getContract("LOVELYRouter")
        //const tokenA_contract = await getERC20Contract(inputFirstToken)
        const routerAddres = await getContractAddress("LOVELYRouter")
        const userAccountAddress: string = String(localStorage.getItem("__account"))
        const tokenA_amount = web3.utils.toWei(inputFirst.current!.value)
        const tokenB_amount = web3.utils.toWei(String(Number(inputSec.current!.value) - 0.5))

        //await tokenA_contract.transferFrom(userAccountAddress, routerAddres, tokenA_amount).send({from: userAccountAddress})
        try {
          await approveToken(inputFirstToken, routerAddres, userAccountAddress, web3.utils.toWei(tokenA_amount))
          errors = false
        } catch (e) {
          console.log(e)
          alert("We cant get approve for swap tokens")
          errors = true
        }

        if (!errors) {
          try {
            await contract.swapExactTokensForTokens(tokenA_amount, 0, [inputFirstToken, inputSecToken], userAccountAddress, Math.floor((Date.now()/1000) + 120)).send({from: userAccountAddress});
            errors = false
          } catch (e) {
            console.log(e)
            alert("We got eeror while swapping tokens. Try again")
            errors = true
          }
        }

        if (!errors) {
          alert("Tokens successfuly swaped")
          inputFirst.current!.value = ""
          setInputFirstToken("")
          inputSec.current!.value = ""
          setInputSecToken("")
        }
        
      }
    }
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={true}>
      <div className={`swap-container ${isLoginedClass}`} style={{color: colors.text, backgroundColor: colors.lightBlack, transition: ".5s"}}>
        <div style={{backgroundColor: colors.lightBlack, color: colors.grey, transition: ".5s"}} className="swap-ads-area">
          ads
        </div>
        <div className="swap-box">
          <div className="swap-manipulating">
            <div className="swap-heading">
              <p className="swap-title">SWAP</p>
              <button className="swap-setting">
                <FontAwesomeIcon style={{color: colors.text, transition: ".5s"}} icon={faGear}/>
              </button>
            </div>
            <div className="swap-body">
              <SwapInput token={inputFirstToken} setToken={setInputFirstToken} colors={colors} onChange={onChangeFirstInput} tokenList={tokenList} setTokenList={setTokenList} inputRef={inputFirst}/>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <ArrowDown colors={colors} />
              </div>
              <SwapInput token={inputSecToken} setToken={setInputSecToken} colors={colors} onChange={onChangeSecondInput} tokenList={tokenList} setTokenList={setTokenList} inputRef={inputSec}/>
              <button className='swap-footer-btn' style={{backgroundColor: colors.darkBlue, color: colors.blue}} onClick={swapAction}>SWAP</button>
            </div>
          </div>
          <div className="swap-footer">
            <button className='swap-footer' style={{backgroundColor: colors.darkBlue, color: colors.blue}} onClick={swapAction}>{isLogined ? "SWAP" : "CONNECT WALLET"}</button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
