import { useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import { PoolFee } from "../../../components/Inputs/PoolFee/PoolFee";
import { getERC20Contract, getContract, approveToken, getContractAddress, getSymbol } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { provider } from "../../../eth";
import Web3 from "web3";

import './PoolLiquidity.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function PoolsLiquidity ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [stateEvent, setStateEvent] = useState<number>(0)

  const [firstToken, setFirstToken] = useState<string>("")
  const [firstTokenName, setFirstTokenName] = useState<string>("")
  const [firstTokenAmount, setFirstTokenAmount] = useState<string>("")
  const [firstTokenImg, setFirstTokenImg] = useState<string>("/icons/binance-icon.svg")
  const [firstTokenUserBalance, setFirstTokenUserBalance] = useState<string>("0")

  const [secToken, setSecToken] = useState<string>("")
  const [secTokenName, setSecTokenName] = useState<string>("")
  const [secTokenAmount, setSecTokenAmount] = useState<string>("")
  const [secTokenImg, setSecTokenImg] = useState<string>("/icons/binance-icon.svg")
  const [secTokenUserBalance, setSecTokenUserBalance] = useState<string>("0")
  const web3 = new Web3(provider)

  useEffect(() => {
    if (firstToken.length === 0) {
      setFirstTokenUserBalance("0")
    } else {
      const getBalance = async () => {
        const contract = getERC20Contract(firstToken)
        const balance: string = await contract.balanceOf(localStorage.getItem("__account")).call()
        setFirstTokenUserBalance(web3.utils.fromWei(balance))
      }
      getBalance()
    }
  }, [firstToken])

  useEffect(() => {
    if (secToken.length === 0) {
      setSecTokenUserBalance("0")
    } else {
      const getBalance = async () => {
        const contract = getERC20Contract(secToken)
        const balance: string = await contract.balanceOf(localStorage.getItem("__account")).call()
        setSecTokenUserBalance(web3.utils.fromWei(balance))
      }
      getBalance()
    }
  }, [secToken])


  useEffect(() => {
    const load = async () => {
      const getQuery = location.search.slice(1, location.search.length).split("&")
      const tokenA: string = getQuery[0].split("=")[1]
      const tokenB: string = getQuery[1].split("=")[1]
      setFirstToken(tokenA)
      setSecToken(tokenB)

      const tokenA_name: string = await getSymbol(tokenA)
      const tokenB_name: string = await getSymbol(tokenB)
      setFirstTokenName(tokenA_name)
      setSecTokenName(tokenB_name)
      
      setStateEvent(stateEvent+1)
    }
    load()
  }, [])

  const addLiquidity = async () => {
    setStateEvent(stateEvent+1)
    let errors: boolean = false
    // if adding liquidity in pool not skiped
    const router = await getContract("LOVELYRouter")
    const routerAddress = await getContractAddress("LOVELYRouter")
    const userAccountAdress: string = String(localStorage.getItem("__account"))
    //approve firs token
    if (!errors) {
      try {
        await approveToken(firstToken, routerAddress, userAccountAdress, web3.utils.toWei(firstTokenAmount))
        errors = false
      } catch (e) {
        errors = true
        console.log(e)
        alert(`Unable to access ${firstTokenName} wallet`)
      }
    }
    //approve secont token
    if (!errors) {
      try {
        await approveToken(secToken, routerAddress, userAccountAdress, web3.utils.toWei(secTokenAmount))
        errors = false
      } catch (e) {
        errors = true
        console.log(e)
        alert(`Unable to access ${secTokenName} wallet`)
      }
    }
    //adding liquidity
    if (!errors) {
      try {
        await router.addLiquidity(firstToken, secToken, web3.utils.toWei(firstTokenAmount), web3.utils.toWei(secTokenAmount), 0, 0, userAccountAdress, Math.floor((Date.now()/1000) + 60)).send({from: userAccountAdress})
        errors = false
      } catch (e) {
        errors = true
        console.log(e)
        alert("We cant add liquidity to pool")
      }
    }

    if (!errors) {setStateEvent(stateEvent+1); rerunSchedule(); setTimeout(() => {navigate(-1)}, 1500)} else (setStateEvent(1))
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-activate">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-activate-heading">
          {stateEvent === 0 ? (
            <>
              <h1>Loading</h1>
            </>
          ) : (
            stateEvent === 1 ? (
              <>
                <FontAwesomeIcon className='pool-liquidity-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>{firstTokenName}-{secTokenName} LP</h1>
                  <span style={{fontSize: 14, color: colors.grey, transition: ".5s"}}>Recieve LP tokens and earn 0.17% trading fees</span>
                </div>
              </>
            ) : (
              stateEvent === 2 ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Adding Liquidity</h1>
                  <span style={{fontSize: 14}}>Awaiting</span>
                </div>
              ) : (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Liquidity Successfully Added</h1>
                  <span style={{fontSize: 14}}>Done</span>
                </div>
              ))
          )}
        </div>
        <div className={`pool-liquidity-body pool-liquidity-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="pool-liquidity-body-box">
              <Loader load={true} />
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="pool-liquidity-body-box">
                  <PoolFee colors={colors} inputValue={setFirstTokenAmount} tokenName={firstTokenName} tokenImg={firstTokenImg} userTokenBalance={firstTokenUserBalance} />
                  {/*<div className="pool-liquidity-only-token">
                    <input onChange={() => {setSplit(!split)}} style={{backgroundColor: colors.black}} className="apple-switch" type="checkbox"></input>
                    <span>I have only BNB.<br/>(Buy second token automatically for 50% of LOVELY).</span>
                  </div>*/}
                  <span className="pool-liquidity-plus">+</span>
                  <PoolFee colors={colors} inputValue={setSecTokenAmount} tokenName={secTokenName} tokenImg={secTokenImg} userTokenBalance={secTokenUserBalance} />
                </div>
            ) : (
              stateEvent === 2 ? (
                <div className="pool-liquidity-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                <div className="pool-liquidity-body-box">
                  <Loader load={false} />
                </div>
              )
            )
          )}
        </div>
        <div className={`pool-liquidity-footer pool-liquidity-footer-${stateEvent}`}>
          {stateEvent === 1 ? (
            <button onClick={addLiquidity}>ADD LIQUIDITY</button>
          ) : ("")}
        </div>
      </div>
    </Layout>
  );
}

export default PoolsLiquidity;