import React, { useCallback, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import { PoolFee } from "../../../components/Inputs/PoolFee/PoolFee";
import { PoolTokenDropdown } from "../../../components/Dropdown/PoolTokenDropdown/PoolTokenDropdown";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { approveToken, getContract, getContractAddress, getERC20Contract } from "../../../eth/ContractController";
import { provider } from "../../../eth";
import Web3 from "web3";

import './PoolAdd.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
  tokenList: any[]
  setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

function PoolAdd ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent, tokenList, setTokenList}: props) {
  const navigate = useNavigate()
  const correctInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const inputVal = e.currentTarget.value.replace(".", ",")
    if (Number(inputVal) > 100) {
      e.currentTarget.value = "100"
    }
    if (Number(inputVal) < 0) {
      e.currentTarget.value = "0"
    }
    
    if(inputVal.indexOf(",") === -1) {
      if (inputVal.length >= 4) {
        e.currentTarget.value = inputVal.slice(0, -1)
      }
    } else {
      if (inputVal.split(',')[0].length > 2) {
        e.currentTarget.value = inputVal.split(',')[0].slice(0, -1) + "." + inputVal.split(',')[1]
      }
      if (inputVal.split(',')[1].length > 1) {
        e.currentTarget.value = inputVal.split(',')[0] + "." + inputVal.split(',')[1].slice(0, -1)
      }
    }
  }, [])
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [poolFee, setPoolFee] = useState<string>("")
  let skiped: boolean = false

  const [firstToken, setFirstToken] = useState<string>("")
  const [firstTokenAmount, setFirstTokenAmount] = useState<string>("")
  const [tokenFirstImg, setFirstTokenImg] = useState<string>("")
  const [tokenFirstName, setFirstTokenName] = useState<string>("")
  const [tokenFirstUserBalance, setFirstUserBalance] = useState<string>("0")

  const [secToken, setSecToken] = useState<string>("")
  const [secTokenAmount, setSecTokenAmount] = useState<string>("")
  const [tokenSecImg, setSecTokenImg] = useState<string>("")
  const [tokenSecName, setSecTokenName] = useState<string>("")
  const [tokenSecUserBalance, setSecUserBalance] = useState<string>("0")
  const web3 = new Web3(provider)

  useEffect(() => {
    if (firstToken.length === 0) {
      setFirstUserBalance("0")
    } else {
      const getBalance = async () => {
        const contract = getERC20Contract(firstToken)
        const balance: string = await contract.balanceOf(localStorage.getItem("__account")).call()
        setFirstUserBalance(web3.utils.fromWei(balance))
      }
      getBalance()
    }
  }, [firstToken])

  useEffect(() => {
    if (secToken.length === 0) {
      setSecUserBalance("0")
    } else {
      const getBalance = async () => {
        const contract = getERC20Contract(secToken)
        const balance: string = await contract.balanceOf(localStorage.getItem("__account")).call()
        setSecUserBalance(web3.utils.fromWei(balance))
      }
      getBalance()
    }
  }, [secToken])

  const addTokens = async () => {
    if (firstToken.length === 0) {
      return alert("Pleace select first token")
    }
    if (secToken.length === 0) {
      return alert("Pleace select second token")
    }
    setStateEvent(stateEvent + 1)
  }

  const addPoolFee = (): void => {
    if (poolFee.length === 0) {
      return alert("Pleace input pool fee amount")
    }
    setPoolFee(poolFee)
    setStateEvent(stateEvent + 1)
  }

  const skipCreatePool = () => {
    skiped = true
    setStateEvent(stateEvent + 1)
    poolCreation()
  }

  const createPool = (): void => {
    if (firstTokenAmount.length === 0) {
      return alert("Pleace input amount of token in first form")
    }
    if (secTokenAmount.length === 0) {
      return alert("Pleace input amount of token in second form")
    }
    setStateEvent(stateEvent + 1)
    poolCreation()
  }

  const poolCreation = async (): Promise<void> => {
    let errors = false
    const userAccountAdress: string | null = String(localStorage.getItem("__account"))
    const contract = await getContract("LOVELYFactory")
    try {
      const createPool = await contract.createValidatedPair(firstToken, secToken, "0x6c2c1FD619b68b59Edf28b692Dc984091ed19B1F", 0, (Number(poolFee)*10)).send({from: userAccountAdress})
      errors = false
    } catch (e) {
      errors = true
      console.log(e)
      alert("We cant create pool")
    }
    // if adding liquidity in pool not skiped
    if (!skiped) {
      const router = await getContract("LOVELYRouter")
      const routerAddress = await getContractAddress("LOVELYRouter")
      //approve firs token
      if (!errors) {
        try {
          await approveToken(firstToken, routerAddress, userAccountAdress, web3.utils.toWei(firstTokenAmount))
          errors = false
        } catch (e) {
          errors = true
          console.log(e)
          alert(`Unable to access ${tokenFirstName} wallet`)
        }
      }
      //approve secont token
      if (!errors) {
        try {
          await approveToken(secToken, routerAddress, userAccountAdress, web3.utils.toWei(secTokenAmount))
          errors = false
        } catch (e) {
          errors = true
          console.log(e)
          alert(`Unable to access ${tokenSecName} wallet`)
        }
      }
      //adding liquidity
      if (!errors) {
        try {
          const liq = await router.addLiquidity(firstToken, secToken, web3.utils.toWei(firstTokenAmount), web3.utils.toWei(secTokenAmount), 0, 0, userAccountAdress, Math.floor((Date.now()/1000) + 60)).send({from: userAccountAdress})
          errors = false
        } catch (e) {
          errors = true
          console.log(e)
          alert("We cant add liquidity to pool")
        }
      }
    }

    //if we dont have arrors -> navigate on next page
    if (!errors) {setStateEvent(stateEvent+1); rerunSchedule(); setTimeout(() => {navigate(-1)}, 1500)} else {setStateEvent(0)}
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-add">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-add-heading">
          {stateEvent === 0 ? (
            <>
              <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
              <h1>Add Liquidity Pool</h1>
            </>
          ) : (stateEvent === 1 ? (
            <>
              <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {setStateEvent(stateEvent-1)}} icon={faArrowLeft} />
              <h1>{tokenFirstName}-{tokenSecName} Fee</h1>
            </>
          ) : (
            stateEvent === 2 ? (
            <>
              <FontAwesomeIcon className='pool-add-heading-icon' onClick={() => {setStateEvent(stateEvent-1)}} icon={faArrowLeft} />
              <div style={{display: "flex", flexDirection: "column"}}>
                <h1>{tokenFirstName}-{tokenSecName} LP</h1>
                <span style={{fontSize: 14, color: colors.grey, transition: ".5s"}}>Recieve LP tokens and earn 0.17% trading fees</span>
              </div>
            </>
          ) : (
            stateEvent === 3 ? (
              <div style={{display: "flex", flexDirection: "column"}}>
                <h1>Adding Pool Liquidity</h1>
                <span style={{fontSize: 14}}>Awaiting</span>
              </div>
            ) : (
              <div style={{display: "flex", flexDirection: "column"}}>
                <h1>Token Successfully Listed</h1>
                <span style={{fontSize: 14}}>Done</span>
              </div>
            )
          )
            ))}
        </div>
        <div className={`pool-add-body pool-add-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="pool-add-body-box">
              <h3>CHOOSE A VALID PAIR</h3>
              <div className="validation-pair-container">
                <PoolTokenDropdown colors={colors} setToken={setFirstToken} tokenList={tokenList} setTokenList={setTokenList} tokenName={tokenFirstName} setTokenName={setFirstTokenName} tokenImg={tokenFirstImg} setTokenImg={setFirstTokenImg} userTokenBalance={tokenFirstUserBalance}/>
                <span>+</span>
                <PoolTokenDropdown colors={colors} setToken={setSecToken} tokenList={tokenList} setTokenList={setTokenList} tokenName={tokenSecName} setTokenName={setSecTokenName} tokenImg={tokenSecImg} setTokenImg={setSecTokenImg} userTokenBalance={tokenSecUserBalance}/>
              </div>
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="pool-add-body-box">
                <h3>CHOOSE VALIDATION FEE</h3>
                <input
                  onChange={(e) => {setPoolFee(e.currentTarget.value)}}
                  style={{color: colors.text, backgroundColor: colors.neutralBlack}}
                  min={0}
                  max={100}
                  step={1}
                  onInput={(e) => {correctInput(e)}}
                  type={"number"}
                  placeholder="0.0%"/>
              </div>
            ) : (
              stateEvent === 2 ? (
                <div className="pool-add-body-box">
                  <PoolFee colors={colors} inputValue={setFirstTokenAmount} tokenName={tokenFirstName} tokenImg={tokenFirstImg} userTokenBalance={tokenFirstUserBalance} />
                  {/*<div className="pool-add-only-token">
                    <input onChange={() => {setSplit(!split)}} style={{backgroundColor: colors.black}} className="apple-switch" type="checkbox"></input>
                    <span>I have only BNB.<br/>(Buy second token automatically for 50% of LOVELY).</span>
                  </div>*/}
                  <span className="pool-add-plus">+</span>
                  <PoolFee colors={colors} inputValue={setSecTokenAmount} tokenName={tokenSecName} tokenImg={tokenSecImg} userTokenBalance={tokenSecUserBalance} />
                </div>
              ) : (
                stateEvent === 3 ? (
                  <div className="pool-add-body-box">
                    <Loader load={true} />
                  </div>
                ) : (
                  <div className="pool-add-body-box">
                    <Loader load={false} />
                  </div>
                )
              )
            )
          )}
        </div>
        <div className={`pool-add-footer pool-add-footer-${stateEvent}`}>
          {stateEvent === 0 ? (
            <button onClick={addTokens}>NEXT</button>
          ) : (stateEvent === 1 ? (
            <button onClick={addPoolFee}>NEXT</button>
            ) : (
              <>
                <button style={{backgroundColor: colors.grey}} className="skip-pool-liq-btn" onClick={skipCreatePool}>SKIP</button>
                <button onClick={createPool}>SUPPLY</button>
              </>
            ))}
        </div>
      </div>
    </Layout>
  );
}

export default PoolAdd;