import "./Loader.styles.css"

export const Loader = ({ load }: any) => {
    if (load) {
        return (
            <div className="LoaderBox loading">
                <img src="/icons/loader.svg" alt="loader" />
                <span>Please wait</span>
            </div>
        )
    } else {
        return (
            <div className="LoaderBox">
                <img src="/icons/done.svg" alt="loader" />
                <span>Done!</span>
            </div>
        )
    }
}