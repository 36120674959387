let themeColor = "dark";

let themeColors = {
    /*dark: {
        text: "#ffffff",
        white: "#ffffff",
        pink: "#ed628b",
        darkPink: "#dd4c76",
        blue: "#4a82cb",
        darkBlue: "#21508a",
        grey: "#585858",
        darkGrey: "#333333",
        neutralBlack: "#262626",
        lightBlack: "#1f1f1f",
        black: "#181818",
        menuActive: "#333333",
    },
    light: {
        text: "#181818",
        white: "#fffff",
        pink: "#ed628b",
        darkPink: "#dd4c76",
        blue: "#21508a",
        darkBlue: "#5a9df5",
        grey: "#a8a8a8",
        darkGrey: "#ffffff",
        neutralBlack: "#eeeeee",
        lightBlack: "#fefefe",
        black: "#e8e8e8",
        menuActive: "#e7e7e7"
    }*/
    dark: {
        text: "#ffffff",
        white: "#ffffff",
        pink: "#ed628b",
        darkPink: "#dd4c76",
        blue: "#4a82cb",
        darkBlue: "#21508a",
        grey: "#585858",
        darkGrey: "#262626",
        neutralBlack: "#1f1f1f",
        lightBlack: "#111111",
        black: "#000000",
        menuActive: "#262626",
    },
    light: {
        text: "#000000",
        white: "#fffff",
        pink: "#ed628b",
        darkPink: "#dd4c76",
        blue: "#21508a",
        darkBlue: "#5a9df5",
        grey: "#585858",
        darkGrey: "#d8d8d8",
        neutralBlack: "#e0e0e0",
        lightBlack: "#eeeeee",
        black: "#ffffff",
        menuActive: "#d8d8d8"
    }
};

export function switchTheme() {
    if (themeColor === "dark") {
        themeColor = "light"
    } else {
        themeColor = "dark"
    }
};

function getColors() {
    const ret = themeColor === "dark" ? themeColors.dark : themeColors.light
    return ret
};

export const getTheme = () => {return getColors()}