import React, { useState } from "react"
import { FeeModal } from "../../Modals/FeeModal/FeeModal"
import "./FeeInput.styles.css"

type props = {
    colors: any
    token: string
    setToken: React.Dispatch<React.SetStateAction<string>>
    inputValue: React.Dispatch<React.SetStateAction<string>>
}

export const FeeInput = ({ colors, token, setToken, inputValue }: props) => {
    const [show, setShow] = useState<boolean>(false)
    const [tokenImg, setTokenImg] = useState<string>("")
    const [tokenName, setTokenName] = useState<string>("")
    return (
        <div className="input-box-fee">
            {show ? (<FeeModal colors={colors} setTokenName={setTokenName} setTokenImg={setTokenImg} setToken={setToken} setShow={setShow} />) : ""}
            <input onChange={e => {inputValue(e.currentTarget.value)}} style={{
                backgroundColor: colors.lightBlack,
                color: colors.text
            }} placeholder={"0"}/>
            <div className="btnSelectFeeToken" style={{backgroundColor: colors.lightBlack}}>
                <button onClick={() => {setShow(true)}}>
                    {tokenImg.length === 0 ? "" : (<img className="feeTokenLogo" src="/icons/binance-icon.svg" alt="token" />)}
                    <span>{token.length === 0 ? "Token" : tokenName}</span>
                </button>
            </div>
        </div>
    )
}