import { useNavigate } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import "./FarmItem.styles.css"

type props = {
    colors: any
    poolItem: any
}

export const FarmItem = ({colors, poolItem}: props) => {
    const navigate = useNavigate()
    return (
        <div className='farm-list-container'>
            {poolItem.amount === "0" ? "" : (
                <div className="pool-farm-container">
                    <span style={{color: colors.text}}>Farming: {poolItem.amount} {poolItem.name}</span>
                </div>
            )}
            <div className="farm-info">
                <div className="farm-info-box">
                    <div className="farm-info-logos">
                        <img src={poolItem.imgA}></img>
                        <img className="info-logos-b" src={poolItem.imgB}></img>
                    </div>
                    <div className="farm-info-heading">
                        <h3 style={{display: "flex", flexDirection: "row", alignItems: "flex-start"}}>{poolItem.name} {
                            poolItem.status === "0" ? (
                                <FontAwesomeIcon style={{color: "#b0b0b0", fontSize: 10, margin: "6px 0 0 5px"}} icon={faCircle} />
                            ) : (
                                poolItem.status === "1" ? (
                                    <FontAwesomeIcon style={{color: "#47bf26"}} icon={faCircle} />
                                ) : (
                                    <FontAwesomeIcon style={{color: "#e20a0a"}} icon={faCircle} />
                                )
                            )
                        }</h3>
                        <p>FARMING POOL</p>
                    </div>
                </div>
                <div className="farm-info-manipulating">
                    <button className="farm" onClick={() => {navigate(`/farm/settings?id=${poolItem.id}&pool=${poolItem.token}&tokenA=${poolItem.tokenA}&tokenB=${poolItem.tokenB}`)}}>
                        <img src="/icons/tractor_icon.svg" alt="icon" />FARM
                    </button>
                </div>
            </div>
        </div>
    )
}