import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../Loader/Loader"
import { isPoolListLoading, poolList } from "../../../eth/ListController"
//import { getContract, getName, getSymbol } from "../../../eth/ContractController"

import "./InputModal.styles.css"

type props = {
    colors: any
    setToken: React.Dispatch<React.SetStateAction<string>>
    setTokenName: React.Dispatch<React.SetStateAction<string>>
    setTokenSecImg: React.Dispatch<React.SetStateAction<string>>
    setTokenImg: React.Dispatch<React.SetStateAction<string>>
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    tokenList: any[]
    setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

export const InputModal = ({colors, setToken, setTokenName, setTokenImg, setTokenSecImg, setShow, tokenList, setTokenList}: props) => {
    const [isLoading, setIsLoading] = useState<boolean>(isPoolListLoading)
    const [firstFive, setFirstFive] = useState<any[]>([])
    const [searchTokens, setSearchTokens] = useState<any[]>([])
    const [searchInput, setSearchInput] = useState<string>("")
    let input: string;
    let typingTimer: any;

    const stateChangeEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {search(input.toLowerCase()); setSearchInput(input)}, 1000)
    }

    const search = (searchingText: string) => {
        let tokens: any[] = []
        for (let i = 0; i < tokenList.length; i++) {
            const tokenName: string = tokenList[i].name.toLowerCase()
            const tokenSymbol: string = tokenList[i].symbol.toLowerCase()
            const token: string = tokenList[i].token.toLowerCase()
            if (token.indexOf(searchingText) !== -1 || tokenSymbol.indexOf(searchingText) !== -1 || tokenName.indexOf(searchingText) !== -1) {
                tokens.push(tokenList[i])
            }
        }
        setSearchTokens(tokens)
    }

    useEffect(() => {
        const reduseFive = (reduse: any[]) => {
            let redused: any[] = []
            for (let i = 0; i < 7; i++) {
                if (reduse[i] !== undefined) {
                    redused.push(reduse[i])
                }
            }
            setFirstFive(redused)
        }
        const asy = async () => {
            const items = poolList
            let items_valid: [object] | any[] = items
            setTokenList([...items_valid])
            reduseFive([...items_valid])
        }
        asy()
        setInterval(() => {
            setIsLoading(isPoolListLoading)
            setTokenList(poolList)
            asy()
        }, 100)
    }, [])

    const selectToken = (item: any) => {
        setToken(item.token)
        setTokenName(item.name)
        setTokenImg(item.imgA)
        setTokenSecImg(item.imgB)
        setShow(false)
    }

    return (
        <div className="choose-token-container">
            <div style={{backgroundColor: colors.neutralBlack, color: colors.text}} className={`choose-token-box isloading-${isLoading}`}>
                {isLoading ? (
                    <div style={{margin: "30px 0"}}>
                        <Loader load={true} />
                    </div>
                ) : (
                    <>
                    <div className="choose-token-header">
                    <div className="token-header-title">
                        <h3>SELECT TOKEN</h3>
                        <FontAwesomeIcon onClick={() => {setShow(false)}} className="closeIcon" icon={faXmark} />
                    </div>
                    <div className="token-header-search-box">
                        <input
                            onKeyUp={(e) => {input = e.currentTarget.value; stateChangeEvent(e)}}
                            onKeyDown={() => {clearTimeout(typingTimer)}}
                            style={{
                                backgroundColor: colors.lightBlack,
                                color: colors.text
                            }}
                            type="text"
                            placeholder="Search name or paste address"
                        />
                    </div>
                    <div className="recent-tokens">
                        {firstFive.map((item) => (
                            <div onClick={() => {selectToken(item)}} key={item.id} style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-button">
                                <img src={item.imgA} alt="token" />
                                <img style={{marginLeft: -13}} src={item.imgB} alt="token" />
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{backgroundColor: colors.lightBlack}} className="choose-token-scroll">
                    <div className="token-scroll-container">
                        {searchTokens.length === 0 ? (
                            searchInput.length === 0 ? (
                                tokenList.map(item => (
                                    <div onClick={() => {selectToken(item)}} key={item.id} className="token-scroll-item">
                                        <img src="/icons/binance-icon.svg" alt="token" />
                                        <img style={{marginLeft: -6}} src="/icons/binance-icon.svg" alt="token" />
                                        <section>
                                            <p>{item.symbol}</p>
                                            <span>{item.name}</span>
                                        </section>
                                    </div>
                                ))
                            ) : (
                                <div className="search-notfound">
                                    <p style={{color: colors.grey}}>Not Found</p>
                                </div>
                            )
                        ) : (
                            searchTokens.map(item => (
                                <div onClick={() => {selectToken(item)}} key={item.id} className="token-scroll-item">
                                    <img src="/icons/binance-icon.svg" alt="token" />
                                    <img style={{marginLeft: -6}} src="/icons/binance-icon.svg" alt="token" />
                                    <section>
                                        <p>{item.name}</p>
                                    </section>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                    </>
                )}
            </div>
        </div>
    )
}