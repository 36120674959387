import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import tokenList from "./feeTokens.json"
import "../InputModal/InputModal.styles.css"

type props = {
    colors: any
    setToken: React.Dispatch<React.SetStateAction<string>>
    setTokenName: React.Dispatch<React.SetStateAction<string>>
    setTokenImg: React.Dispatch<React.SetStateAction<string>>
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const FeeModal = ({colors, setToken, setTokenName, setTokenImg, setShow}: props) => {
    let tokenListFirst: any[] = []
    
    for (let i = 0; i < 5; i++) {
        if (tokenList[i] !== undefined) {
            tokenListFirst.push(tokenList[i])
        }
    }

    const selectToken = (item: any) => {
        setToken(item.token)
        setTokenName(item.symbol)
        setTokenImg(item.img)
        setShow(false)
    }

    return (
        <div className="choose-token-container">
            <div style={{backgroundColor: colors.neutralBlack, color: colors.text}} className={`choose-token-box isloading-false`}>
                <div className="choose-token-header">
                    <div className="token-header-title">
                        <h3>SELECT TOKEN</h3>
                        <FontAwesomeIcon onClick={() => {setShow(false)}} className="closeIcon" icon={faXmark} />
                    </div>
                    <div className="token-header-search-box">
                        <input style={{backgroundColor: colors.lightBlack, color: colors.text}} type="text" placeholder="Search name or paste address" />
                    </div>
                    <div className="recent-tokens">
                        {tokenListFirst.map((item) => (
                        <div onClick={() => {selectToken(item)}} key={item.id} style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-button">
                            <img src={item.img} alt="token" />
                            <span>{item.symbol}</span>
                        </div>
                        ))}
                    </div>
                </div>
                <div style={{backgroundColor: colors.lightBlack}} className="choose-token-scroll">
                    <div className="token-scroll-container">
                        {tokenList.map(item => (
                            <div onClick={() => {selectToken(item)}} key={item.id} className="token-scroll-item">
                                <img src="/icons/binance-icon.svg" alt="token" />
                                <section>
                                    <p>{item.symbol}</p>
                                    <span>{item.name}</span>
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}