import { providers } from "ethers"
import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { bsc, bscWalletConnect } from "./Chains";

declare global {
    interface Window {
      ethereum: any;
      web3: any;
      ethereumMetamask: any;
    }
}

//lokal strorage
export let provider: any = null;
export let __account: string = "";
export let __balance: number | null = null;

//initiation for wallet connect bridge provider
const chainNet = bscWalletConnect()
const newWalletConnectProvider = () => {return new WalletConnectProvider(chainNet)};
let walletConnectProvider = newWalletConnectProvider();

//check and return right provider for connection to wallet
const checkProvider = () => {
    if (window.ethereum?.isMetaMask !== undefined) {
        //set provider in local storage
        provider = window.ethereum

        return window.ethereum
    } else {
        //set provider globaly
        window.ethereum = walletConnectProvider
        //set provider in local storage
        provider = window.ethereum

        return walletConnectProvider
    }
}
checkProvider()

//get last block in chain
export const getBlock = async () => {
    const blockNumber = new providers.Web3Provider(walletConnectProvider).getBlockNumber().then((result) => {return result})
    return await blockNumber
}

class AccountController {

    // initiate connection to wallet
    async initConection () {
        checkProvider()
        if (window.ethereum?.isMetaMask !== undefined) {
            return await this.connectMetamak();
        } else {
            return await this.walletConnect(provider);
        }
    }

    //if metamask is instaled
    async connectMetamak() {
        let accounts;
        let balance;
        const web3Instance = new Web3(provider);
        
        //try to change network
        try {
            await window.ethereum.request(bsc);
        } catch (e) {
            alert("Error: cannot change blockchain network!!!")
            console.log(e)
        }


        //connect account and get address
        try {
            accounts = await window.ethereum.request({method: "eth_requestAccounts"});
        } catch (e) {
            alert("Error: cannot get account information!!!")
            console.log(e)
        }

        //get account balance
        try {
            balance = await web3Instance.eth.getBalance(accounts[0]);
            balance = web3Instance.utils.fromWei(balance);
        } catch (e) {
            alert("Error: cannot get account balance!!!")
        }

        //setting variables in local browser storage
        __account = accounts[0];
        __balance = Number(balance);
        localStorage.setItem("__account", accounts[0]);
        localStorage.setItem("__balance", String(balance));

        return true;
    }

    //if metamask not installed
    async walletConnect(provider: any) {
        await walletConnectProvider
        .enable()
        .then(async () => {
            const web3Instance = new Web3(provider);

            //request changing of network
            window.ethereum.request(bsc);

            //request connection and some data from wallet
            const accounts = await web3Instance.eth.getAccounts();
            let balance = await web3Instance.eth.getBalance(accounts[0]);
            balance = web3Instance.utils.fromWei(balance);

            //setting variables in local browser storage
            localStorage.setItem("__account", accounts[0]);
            localStorage.setItem("__balance", balance);
            return true;
        })
        .catch((error: any) => {
            walletConnectProvider = newWalletConnectProvider();
            console.log("Error: Wallet connection down!!!\n" + error);
            return false;
        });

        //get accout data
        __account = String(localStorage.getItem("__account"));
        __balance = Number(localStorage.getItem("__balance"));
        return true;
    }

    async disconnectWallet() {
        localStorage.removeItem("__account");
        localStorage.removeItem("__balance");
        //await window.ethereum.currentProvider.disconnect();
    }
}

const accountController = new AccountController()
export const initWalletConnection = async () => {return await accountController.initConection()}
export const initWalletDisconnection = async () => {return await accountController.disconnectWallet()}