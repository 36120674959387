import Marquee from "react-fast-marquee";

export const News = () => {
    return (
        <div className="news-line">
            <Marquee gradient={false}>
                <div style={{paddingRight: 30}}>TOP Gainers: BNB, $DRSL, $LOVELY. TOP Coins: BUSD, WBNB, HUMP. TOP Liquidity: USDT, WETH, LOVELY.</div>
                <div style={{paddingRight: 30}}>TOP Gainers: BNB, $DRSL, $LOVELY. TOP Coins: BUSD, WBNB, HUMP. TOP Liquidity: USDT, WETH, LOVELY.</div>
                <div style={{paddingRight: 30}}>TOP Gainers: BNB, $DRSL, $LOVELY. TOP Coins: BUSD, WBNB, HUMP. TOP Liquidity: USDT, WETH, LOVELY.</div>
            </Marquee>
        </div>
    )
}