import { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { Layout } from '../Layout';
import { isPoolListLoading, poolList } from '../../eth/ListController';
import { PoolItem } from '../../components/PoolItem/PoolItem';
import './Pools.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function Pools({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  let navigate = useNavigate()
  const [pools, setPools] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const load = async () => {
      setPools(poolList)
      setIsLoading(isPoolListLoading)
    }
    setInterval(() => {
      load()
    }, 100)
  }, [])

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div className="pools-frame">
        <div className="pools-header">
          <div className="pools-header-text">
            <h1 style={{color: colors.text}}>LIQUIDITY TOKENS OVERVIEW</h1>
            <p style={{color: colors.grey}}>Remove liquidity to resive tokens back</p>
          </div>
          <div className="pools-header-manip">
            <button onClick={() => {navigate('/token/add')}} style={{marginRight: 25}}>LIST A TOKEN</button>
            <button onClick={() => {navigate('/pools/add')}}>CREATE POOL</button>
            <button onClick={() => {navigate('/farms')}} style={{marginLeft: 25}}>FARM</button>
          </div>
        </div>
        <div style={{color: colors.grey, backgroundColor: colors.lightBlack}} className="pools-adv">
          <p>ADV</p>
        </div>
        <div style={{color: colors.grey, backgroundColor: colors.neutralBlack}} className="pools-overview-box">
          {isLoading ? (
              <div>
                <Loader load={true} />
              </div>
            ) : (
              pools.length === 0 ? (
                <div className='pools-emty'>
                  <img src='/icons/inbox_icon.png' alt='inbox' />
                  <p>NO LIQUIDITY POOLS FOUND.<br/>NEW POSITIONS WILL APPEAR HERE.</p>
                </div>
              ) : (
                <div>
                  {pools.map((item) => (
                    <PoolItem key={item.id} colors={colors} poolItem={item}/>
                  ))}
                </div>
              )
            )}
        </div>
      </div>
    </Layout>
  );
}

export default Pools;
