import { useState, useEffect } from "react"
import Web3 from "web3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from "../../Layout";
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import { getERC20Contract, getContract, approveToken, getContractAddress, getSymbol } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { WithdrawFarm } from "../../../components/FarmBody/WithdrawFarm/WithdrawFarm";
import { AddingFarm } from "../../../components/FarmBody/AddingFarm/AddingFarm";
import { getUserFarmBalance } from "../../../eth/FarmController";
import { provider } from "../../../eth";

import './FarmSettings.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function FarmSettings ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [isFarming, setIsFarming] = useState<boolean>(false)
  const [farmId, setFarmId] = useState<string>("")
  const [poolToken, setPoolToken] = useState<string>("")
  const [poolUserBalance, setPoolUserBalance] = useState<string>("0")

  const [farmUserBalance, setFarmUserBalance] = useState<string>("0")

  const [firstToken, setFirstToken] = useState<string>("")
  const [firstTokenName, setFirstTokenName] = useState<string>("")

  const [secToken, setSecToken] = useState<string>("")
  const [secTokenName, setSecTokenName] = useState<string>("")
  const web3 = new Web3(provider)

  useEffect(() => {
    const load = async () => {
      const getQuery = location.search.slice(1, location.search.length).split("&")
      const farmId: string = getQuery[0].split("=")[1]
      const poolToken = getQuery[1].split("=")[1]
      const tokenA: string = getQuery[2].split("=")[1]
      const tokenB: string = getQuery[3].split("=")[1]
      setFarmId(farmId)
      setPoolToken(poolToken)
      setFirstToken(tokenA)
      setSecToken(tokenB)

      const tokenA_name: string = await getSymbol(tokenA)
      const tokenB_name: string = await getSymbol(tokenB)
      setFirstTokenName(tokenA_name)
      setSecTokenName(tokenB_name)

      const farmBalance = await getUserFarmBalance(poolToken)
      if (Number(farmBalance) > 0) {
        setIsFarming(true)
      }
      setFarmUserBalance(farmBalance)

      const constract = await getERC20Contract(poolToken)
      const poolBalance = await constract.balanceOf(String(localStorage.getItem("__account"))).call()
      setPoolUserBalance(poolBalance)
      
      setStateEvent(stateEvent+1)
    }
    load()
  }, [])

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-activate">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-activate-heading">
          {stateEvent === 0 ? (
            <>
              <h1>Loading</h1>
            </>
          ) : (
            stateEvent === 1 ? (
              <>
                <FontAwesomeIcon className='farm-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>{firstTokenName}-{secTokenName} Farm</h1>
                  <span style={{fontSize: 14, color: colors.text, transition: ".5s"}}>Earned <span style={{color: "#ed628b" }}>{web3.utils.fromWei(farmUserBalance)}</span> {firstTokenName}-{secTokenName}</span>
                </div>
              </>
            ) : (
              stateEvent === 2 ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Adding Tokens In Farm</h1>
                  <span style={{fontSize: 14}}>Awaiting</span>
                </div>
              ) : (
                stateEvent === 3 ? (
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <h1>Tokens Successfully Added</h1>
                    <span style={{fontSize: 14}}>Done</span>
                  </div>
                ) : (
                  stateEvent === 4 ? (
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <h1>Withdrawing Your Tokens</h1>
                      <span style={{fontSize: 14}}>Awaiting</span>
                    </div>
                  ) : (
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <h1>Tokens Successfully Withdrawed</h1>
                      <span style={{fontSize: 14}}>Done</span>
                    </div>
                  )
                )
              ))
          )}
        </div>
        <div className={`farm-body farm-state-${stateEvent} ${stateEvent === 1 ? `is-farming-${isFarming}` : ""}`}>
          {stateEvent === 0 ? (
            <div className="farm-body-box">
              <Loader load={true} />
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="farm-body-box">
                {isFarming ? (
                  <>
                    <WithdrawFarm
                      colors={colors}
                      poolName={`${firstTokenName}-${secTokenName}`}
                      stateEvent={stateEvent}
                      setStateEvent={setStateEvent}
                      poolToken={poolToken}
                    />
                    <section style={{height: 75}} />
                  </>
                ) : ""}
                <AddingFarm 
                  colors={colors}
                  poolName={`${firstTokenName}-${secTokenName}`}
                  stateEvent={stateEvent}
                  setStateEvent={setStateEvent}
                  poolAddress={poolToken}
                  farmId={farmId}
                  poolUserBalance={poolUserBalance}
                />
              </div>
            ) : (
              stateEvent === 2 ? (
                <div className="farm-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                stateEvent === 3 ? (
                  <div className="farm-body-box">
                    <Loader load={false} />
                  </div>
                ) : (
                  stateEvent === 4 ? (
                    <div className="farm-body-box">
                      <Loader load={true} />
                    </div>
                  ) : (
                    <div className="farm-body-box">
                      <Loader load={false} />
                    </div>
                  )
                )
              )
            )
          )}
        </div>
      </div>
    </Layout>
  );
}

export default FarmSettings;