import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import { getTheme, switchTheme }from "./theme/controller"
import { initWalletConnection, initWalletDisconnection } from './eth';
import { scheduleScriptLoading } from './eth/NativeScheduler';
import { Navigation } from "./navigation";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const RouteController = () => {
  const [theme, setThemeCol] = useState(getTheme())
  const [isLogined, setLogin] = useState<boolean>(false)
  const [isLoginedClass, setLoginedClass] = useState<string>("mani-min")
  const [tokenList, setTokenList] = useState<any[]>([])

  const themeSwitcher = () => {
    switchTheme()
    setThemeCol(getTheme())
  }

  const loginEvent = async () => {
    const wallet = await initWalletConnection()
    if (wallet) {
      setLogin(true)
      setLoginedClass("mani-max")
      scheduleScriptLoading()
    }
  }

  const logoutEvent = async () => {
    await initWalletDisconnection()
    setLogin(false)
    setLoginedClass("mani-min")
  }

  const checkLogin = async () => {
    if (!isLogined && localStorage.getItem("__account") !== null) {
      const wallet = await initWalletConnection()
      if (wallet) {
        setLogin(true)
        setLoginedClass("mani-max")
        scheduleScriptLoading()
      }
    }
  }
  checkLogin()

  document.body.style.backgroundColor = theme.black

  return (
    <BrowserRouter>
      <Navigation
        theme={theme}
        themeSwitcher={themeSwitcher}
        isLogined={isLogined}
        setLogin={setLogin}
        isLoginedClass={isLoginedClass}
        setLoginedClass={setLoginedClass}
        loginEvent={loginEvent}
        logoutEvent={logoutEvent}
        tokenList={tokenList}
        setTokenList={setTokenList}
      />
    </BrowserRouter>
  );
}

root.render(
  <RouteController />
);