import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate } from 'react-router-dom';
import { FeeInput } from "../../../components/Inputs/TokenFee/FeeInput";
import { Loader } from "../../../components/Loader/Loader";
import Web3 from "web3";
import { getContract } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { provider } from "../../../eth";

import './TokenAdd.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function TokenAdd ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  const navigate = useNavigate()
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [tokenInp, setTokenInp] = useState<string>("")
  const [feeInp, setFeeInp] = useState("")
  const [feeToken, setFeeToken] = useState<string>("")
  const web3 = new Web3(provider)

  const checkToken = () => {
    if (tokenInp.length >= 1 ) {
      let errors = false
      if (tokenInp.indexOf("http") === -1 || tokenInp.indexOf("https") === -1 || tokenInp.indexOf("://") === -1) {
        try {
          const t = web3.utils.toChecksumAddress(tokenInp)
          errors = false
          setTokenInp(t)
        } catch (e) {
          alert("Token is not valid Etherium address")
          console.log(e)
          errors = true
        }
      } else {
        const urlToToken = tokenInp.split("address/")[1].split("#")[0]
        try {
          const t = web3.utils.toChecksumAddress(urlToToken)
          errors = false
          setTokenInp(t)
        } catch (e) {
          alert("Token is not valid Etherium address")
          console.log(e)
          errors = true
        }
      }
      
      if (!errors) {setStateEvent(stateEvent+1)}
    }
  }

  const tokenFee = () => {
    if (feeToken.length === 0) {
      return alert("Choose Fee Token!!!")
    }

    if (feeInp.length === 0) {
      return alert("Type fee amount!!!")
    }

    setStateEvent(stateEvent+1)
    addTokenOnPlatform()
  }

  const addTokenOnPlatform = async () => {
    const contract = await getContract("LOVELYTokenList")
    const __account = String(localStorage.getItem("__account"))
    let errors = false
    try {
      const addingResult = await contract.add(tokenInp, feeToken, web3.utils.toWei(feeInp)).send({from: web3.utils.toChecksumAddress(__account)})
      errors = false
    } catch (e) {
      alert("Error: transaction was failed!!!")
      console.log(e)
      errors = true
    }
    
    //done message and run on pools page if we dont have errors
    if (!errors) {setStateEvent(3); rerunSchedule();setTimeout(() => {navigate(-1)}, 2500)}
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.neutralBlack, color: colors.text}} className="token-add">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-add-heading">
          {stateEvent === 0 ? (
            <>
              <FontAwesomeIcon className='token-add-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
              <h1>List A Token</h1>
            </>
          ) : (stateEvent === 1 ? (
            <>
              <FontAwesomeIcon className='token-add-heading-icon' onClick={() => {setStateEvent(stateEvent-1)}} icon={faArrowLeft} />
              <h1>Token Fee</h1>
            </>
          ) : (
            stateEvent === 2 ? (
              <div style={{display: "flex", flexDirection: "column"}}>
                <h1>Adding Token Fee</h1>
                <span style={{fontSize: 14}}>Awaiting</span>
              </div>
            ) : (
              <div style={{display: "flex", flexDirection: "column"}}>
                <h1>Token Successfully Listed</h1>
                <span style={{fontSize: 14}}>Awaiting</span>
              </div>
            )))}
        </div>
        <div className={`token-add-body add-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="token-add-body-box">
              <h3>TOKEN ADDRESS OR URL</h3>
              <input onChange={(e) => {setTokenInp(e.currentTarget.value)}} style={{color: colors.text, backgroundColor: colors.lightBlack}} type="text" placeholder='Token Addres Or URL'/>
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="token-add-body-box">
                <h3>CHOOSE VALIDATION FEE</h3>
                <FeeInput inputValue={setFeeInp} token={feeToken} setToken={setFeeToken} colors={colors}/>
              </div>
            ) : (
              stateEvent === 2 ? (
                <div className="token-add-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                <div className="token-add-body-box">
                  <Loader load={false} />
                </div>
              )
            )
          )}
        </div>
        <div className={`token-add-footer add-footer-${stateEvent}`}>
          {stateEvent === 0 ? (
            <button onClick={checkToken}>NEXT</button>
          ) : (stateEvent === 1 ? (
            <button onClick={tokenFee}>ADD FEE</button>
            ) : (<div></div>))}
        </div>
      </div>
    </Layout>
  );
}

export default TokenAdd;