import Web3 from "web3";
import { AbiItem } from "web3-utils"
import { provider } from ".";

import addresses from "../artifacts/addresses.json";
import ERC20 from "../artifacts/@uniswap/v2-periphery/contracts/interfaces/IERC20.sol/IERC20.json"
import LOVELYFactory from "../artifacts/contracts/LOVELYFactory.sol/LOVELYFactory.json";
import LOVELYRouter from "../artifacts/contracts/LOVELYRouter.sol/LOVELYRouter.json";
import LOVELYTokenList from "../artifacts/contracts/LOVELYTokenList.sol/LOVELYTokenList.json";
import LOVELYFarm from "../artifacts/contracts/LOVELYFarm.sol/LOVELYFarm.json"
import LOVELYPairToken from "../artifacts/contracts/LOVELYPairToken.sol/LOVELYPairToken.json"

class ContractController {
    web3Interface = new Web3(provider);

    async getContract(name: string, address?: string) {
        if (name === "LOVELYFactory") {
            const contract = new this.web3Interface.eth.Contract(LOVELYFactory.abi as unknown as AbiItem, addresses.LOVELYFactory);
            return contract.methods;
        }
        
        if (name === "LOVELYRouter") {
            const contract = new this.web3Interface.eth.Contract(LOVELYRouter.abi as unknown as AbiItem, addresses.LOVELYRouter);
            return contract.methods;
        }

        if (name === "LOVELYTokenList") {
            const factory = new this.web3Interface.eth.Contract(LOVELYFactory.abi as unknown as AbiItem, addresses.LOVELYFactory);
            const tokenListAddress = await factory.methods.getTokenList().call();
            const contract = new this.web3Interface.eth.Contract(LOVELYTokenList.abi as unknown as AbiItem, this.web3Interface.utils.toChecksumAddress(tokenListAddress));
            return contract.methods;
        }

        if (name === "LOVELYFarm") {
            const contract = new this.web3Interface.eth.Contract(LOVELYFarm.abi as unknown as AbiItem, addresses.LOVELYFarm);
            return contract.methods;
        }

        if (name === "LOVELYPairToken") {
            const contract = new this.web3Interface.eth.Contract(LOVELYPairToken.abi as unknown as AbiItem, address);
            return contract.methods;
        }
    }

    getERC20Contract (address: string): any {
        const contract = new this.web3Interface.eth.Contract(ERC20.abi as unknown as AbiItem, address);
        return contract.methods
    }

    async getContractAddress(name: string): Promise<string> {
        let tokenAddress: string = ""

        if (name === "LOVELYTokenList") {
            const factory = new this.web3Interface.eth.Contract(LOVELYFactory.abi as unknown as AbiItem, addresses.LOVELYFactory);
            tokenAddress = await factory.methods.getTokenList().call();
        }

        if (name === "LOVELYRouter") {
            tokenAddress = addresses.LOVELYRouter
        }

        if (name === "LOVELYFactory") {
            tokenAddress = addresses.LOVELYFactory
        }

        if (name === "LOVELYFarm") {
            tokenAddress = addresses.LOVELYFarm
        }

        return tokenAddress;
    }

    async getName(token: string): Promise<string> {
        const address_token = this.web3Interface.utils.toChecksumAddress(token)
        const contract = new this.web3Interface.eth.Contract(ERC20.abi as unknown as AbiItem, address_token);
        let name;
        try {
            name = await contract.methods.name().call();
        } catch (e) {
            console.log(e)
            name = "Some Token"
        }
        return name;
    }

    async getSymbol(token: string): Promise<string> {
        const address_token = this.web3Interface.utils.toChecksumAddress(token)
        const contract = new this.web3Interface.eth.Contract(ERC20.abi as unknown as AbiItem, address_token);
        let symbol;
        try {
            symbol = await contract.methods.symbol().call();
        } catch (e) {
            console.log(e)
            symbol = "STOKEN"
        }
        return symbol;
    }

    async approve(tokenFeeAddress: string, tokenAddress: string, account: string, value: string) {
        const contract = new this.web3Interface.eth.Contract(ERC20.abi as unknown as AbiItem, this.web3Interface.utils.toChecksumAddress(tokenFeeAddress));
        return await contract.methods.approve(tokenAddress, value).send({from: account})
    }
}

const contractController = new ContractController();
export const getContract = async (name: string, address?: string) => {return await contractController.getContract(name, address)};
export const getERC20Contract = (address: string) => {return contractController.getERC20Contract(address)}
export const getContractAddress = async (name: string): Promise<string> => {return await contractController.getContractAddress(name)}
export const getName = async (token: string) => {return await contractController.getName(token)};
export const getSymbol = async (token: string) => {return await contractController.getSymbol(token)};
export const approveToken = async (tokenFeeAddress: string, tokenAddress: string, account: string, fee: string) => {return await contractController.approve(tokenFeeAddress, tokenAddress, account, fee)}