import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { InputModal } from "../../Modals/InputModal/InputModal"

import "./PoolTokenDropdown.styles.css"

type props = {
    colors: any
    setToken: React.Dispatch<React.SetStateAction<string>>
    tokenList: any[]
    setTokenList: React.Dispatch<React.SetStateAction<any[]>>
    tokenName: string
    setTokenName: React.Dispatch<React.SetStateAction<string>>
    tokenImg: string
    setTokenImg: React.Dispatch<React.SetStateAction<string>>
    userTokenBalance: string
}

export const PoolTokenDropdown = ({colors, setToken, tokenList, setTokenList, tokenName, setTokenName, tokenImg, setTokenImg, userTokenBalance}: props) => {
    const [show, setShow] = useState<boolean>(false)

    return (
        <>
            {show ? (<InputModal colors={colors} setToken={setToken} setShow={setShow} setTokenName={setTokenName} setTokenImg={setTokenImg} tokenList={tokenList} setTokenList={setTokenList} />) : ""}
            <div className="pool-token-select-container">
                <button
                    onClick={() => {setShow(true)}}
                    style={{backgroundColor: colors.lightBlack}}
                    className="select-token-btn"
                >
                    <section>
                        {tokenName.length === 0 && tokenImg.length === 0 ? (
                            <span style={{color: colors.text, transition: ".5s"}}>SELECT</span>
                        ) : (
                            <>
                                <img src={tokenImg} alt="token"/>
                                <span style={{color: colors.text, transition: ".5s"}}>{tokenName}</span>
                            </>
                        )}
                    </section>
                    <FontAwesomeIcon style={{color: colors.text, fontWeight: 600, transition: ".5s"}} icon={faChevronDown}/>
                </button>
                <div className="pts-balance-container">
                    <div className="pts-balance">
                        <span className="in-usd">{userTokenBalance}</span>
                        <span className="balance-text">Balance:</span>
                    </div>
                    <div className="balance-in-dollar">
                        <span>~$0,00</span>
                    </div>
                </div>
            </div>
        </>
    )
}