import { useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MouseEventHandler } from 'react';
import { Layout } from '../../Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from "../../../components/Loader/Loader";
import { PoolFee } from "../../../components/Inputs/PoolFee/PoolFee";
import { getERC20Contract, getContract, getContractAddress, approveToken, getSymbol } from "../../../eth/ContractController";
import { rerunSchedule } from "../../../eth/NativeScheduler";
import { provider } from "../../../eth";

import Web3 from "web3";

import './RemoveLiquidity.styles.css';

type props = {
  colors: any
  themeModificator: MouseEventHandler
  isLogined: boolean
  setLogin: any
  isLoginedClass: string
  setLoginedClass: any
  loginEvent: any
  logoutEvent: any
}

function RemoveLiquidity ({colors, themeModificator, isLogined, setLogin, isLoginedClass, setLoginedClass, loginEvent, logoutEvent}: props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [stateEvent, setStateEvent] = useState<number>(0)
  const [poolLiquidity, setPoolLiquidity] = useState<string>("")
  const [poolAddress, setPoolAddress] = useState<string>("")

  const [firstToken, setFirstToken] = useState<string>("")
  const [firstTokenName, setFirstTokenName] = useState<string>("")
  const [firstTokenAmount, setFirstTokenAmount] = useState<string>("0")
  const [firstTokenImg, setFirstTokenImg] = useState<string>("/icons/binance-icon.svg")

  const [secToken, setSecToken] = useState<string>("")
  const [secTokenName, setSecTokenName] = useState<string>("")
  const web3 = new Web3(provider)

  useEffect(() => {
    const load = async () => {
      const getQuery = location.search.slice(1, location.search.length).split("&")
      const pool: string = getQuery[0].split("=")[1]
      const tokenA: string = getQuery[1].split("=")[1]
      const tokenB: string = getQuery[2].split("=")[1]
      setFirstToken(tokenA)
      setSecToken(tokenB)
      setPoolAddress(pool)

      //what parcent of tokens in pair user have?
      const contract = await getERC20Contract(pool)
      const myPoolTokensAmount: string = await contract.balanceOf(localStorage.getItem("__account")).call()
      setPoolLiquidity(myPoolTokensAmount)

      const tokenA_name: string = await getSymbol(tokenA)
      const tokenB_name: string = await getSymbol(tokenB)
      setFirstTokenName(tokenA_name)
      setSecTokenName(tokenB_name)
      
      setStateEvent(stateEvent+1)
    }
    load()
  }, [])

  const removeLiquidity = async () => {
    setStateEvent(stateEvent+1)
    let errors: boolean = false
    // removing liquidity from pool
    const router = await getContract("LOVELYRouter")
    const userAccountAdress: string = String(localStorage.getItem("__account"))
    
    //getting approve for manipulating with tokens
    try {
      const routerAddress = await getContractAddress("LOVELYRouter")
      await approveToken(poolAddress, routerAddress, userAccountAdress, web3.utils.toWei(firstTokenAmount))
      errors = false
    } catch (e) {
      console.log(e)
      alert("We cant get approve for sending your tokens")
      errors = true
    }
    
    //removing liquidity
    try {
      await router.removeLiquidity(firstToken, secToken, web3.utils.toWei(firstTokenAmount), 0, 0, userAccountAdress, Math.floor((Date.now()/1000) + 60)).send({from: userAccountAdress})
      errors = false
    } catch (e) {
      console.log(e)
      alert("We received error while trying to return your tokens. Try again")
      errors = true
    }

    if (!errors) {setStateEvent(stateEvent+1); setTimeout(() => {navigate(-1)}, 1500)} else (setStateEvent(1))
  }

  return (
    <Layout title="DEX LOVELY FINANCE" colors={colors} themeModificator={themeModificator} isLogined={isLogined} setLogin={setLogin} setLoginedClass={setLoginedClass} loginEvent={loginEvent} logoutEvent={logoutEvent} menuActive={false}>
      <div style={{backgroundColor: colors.lightBlack, color: colors.text}} className="token-activate">
        <div style={{color: colors.text, borderColor: colors.grey}} className="token-activate-heading">
          {stateEvent === 0 ? (
            <>
              <h1>Loading</h1>
            </>
          ) : (
            stateEvent === 1 ? (
              <>
                <FontAwesomeIcon className='removepool-liquidity-heading-icon' onClick={() => {navigate(-1)}} icon={faArrowLeft} />
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>{firstTokenName}-{secTokenName} LP</h1>
                  <span style={{fontSize: 14, color: colors.grey, transition: ".5s"}}>Recieve your LP tokens</span>
                </div>
              </>
            ) : (
              stateEvent === 2 ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Removing Liquidity</h1>
                  <span style={{fontSize: 14}}>Awaiting</span>
                </div>
              ) : (
                <div style={{display: "flex", flexDirection: "column"}}>
                  <h1>Liquidity Successfully Removed</h1>
                  <span style={{fontSize: 14}}>Done</span>
                </div>
              ))
          )}
        </div>
        <div className={`removepool-liquidity-body removepool-liquidity-state-${stateEvent}`}>
          {stateEvent === 0 ? (
            <div className="removepool-liquidity-body-box">
              <Loader load={true} />
            </div>
          ) : (
            stateEvent === 1 ? (
              <div className="removepool-liquidity-body-box">
                <PoolFee colors={colors} inputValue={setFirstTokenAmount} tokenName={`${firstTokenName}-${secTokenName}`} tokenImg={firstTokenImg} userTokenBalance={web3.utils.fromWei(poolLiquidity)} isRemovePage={true}/>
              </div>
            ) : (
              stateEvent === 2 ? (
                <div className="removepool-liquidity-body-box">
                  <Loader load={true} />
                </div>
              ) : (
                <div className="removepool-liquidity-body-box">
                  <Loader load={false} />
                </div>
              )
            )
          )}
        </div>
        <div className={`removepool-liquidity-footer removepool-liquidity-footer-${stateEvent}`}>
          {stateEvent === 1 ? (
            <button onClick={removeLiquidity}>REMOVE</button>
          ) : ("")}
        </div>
      </div>
    </Layout>
  );
}

export default RemoveLiquidity;