import { IWalletConnectConnectorOptions } from "web3modal/dist/providers/connectors/walletconnect"

const isMainnet = false

export const bsc = isMainnet ? ({
    method: "wallet_addEthereumChain",
    params: [{
        chainId: "0x38",
        rpcUrls: ["https://bsc-dataseed1.binance.org/"],
        chainName: "Binance Smart Chain",
        nativeCurrency: {
            name: "Binance Coin",
            symbol: "BNB",
            decimals: 18
        },
        blockExplorerUrls: ["https://bscscan.com/"]
    }]
}) : (
    {
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x61",
            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
            chainName: "BNB Smart Chain Testnet",
            nativeCurrency: {
                name: "tBNB",
                symbol: "tBNB",
                decimals: 18
            },
            blockExplorerUrls: ["https://bscscan.com/"]
        }]
    }
)

export const bscWalletConnect = (): IWalletConnectConnectorOptions => isMainnet ? ({
    rpc: {
        56: "https://bsc-dataseed1.binance.org"
    },
    network: "binance",
}) : ({
    rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
    },
    network: "binance",
})