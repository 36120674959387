import { MouseEventHandler, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon, faChevronDown, faCircle } from "@fortawesome/free-solid-svg-icons"
import "./Network.styles.css"

type props = {
    colors: any
    themeModificator: MouseEventHandler
    isLogined: boolean
    loginEvent: any
    logoutEvent: any
}

export const NetworkManipulations = ({colors, themeModificator, isLogined, loginEvent, logoutEvent}: props) => {
    const [isdropActive, setDropActive] = useState<number>(1)
    const [dropActive, setActive] = useState<boolean>(false)

    const openNetworks = () => {
        if (!dropActive) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const loginAction = () => {        
        if (!isLogined) {
            loginEvent()
        } else {
            logoutEvent()
        }
    }

    return (
        <div className="r-header-container" style={{backgroundColor: colors.black}}>
            <div className="r-box">
                <div className="net-net">
                    <div className="net-container">
                        <button style={{
                            backgroundColor: colors.lightBlack,
                            color: colors.text,
                            borderColor: colors.lightBlack
                            }} className="net-wal" onClick={openNetworks}>
                            <img style={{marginRight: "15px"}} src="/icons/binance-icon.svg" alt="binance logo"></img>
                            BSC<br/>MAINNET
                            <FontAwesomeIcon style={{color: colors.text, marginLeft: "15px", transition: ".5s"}} icon={faChevronDown}/>
                        </button>
                        <div className={`net-dropdown-container net-${dropActive}`}>
                            <div style={{backgroundColor: colors.lightBlack}} className="net-dropdown">
                                <h3>SELECT A NETWORK</h3>
                                <div style={isdropActive === 0 ? {backgroundColor: colors.menuActive} : {backgroundColor: "transparent"}} className="dropdown-btn">
                                    <div className="dropdown-text">
                                        <img src="/icons/binance-icon.svg" alt="network logo"></img>
                                        <p>BSC Mainnet</p>
                                        { isdropActive === 0 ? (<section>
                                            <FontAwesomeIcon style={{color: "green"}} icon={faCircle}/>
                                        </section>) : "" }
                                    </div>
                                    { isdropActive === 0 ? (<a href="#">BNB Explorer</a>) : '' }
                                </div>
                                <div style={isdropActive === 1 ? {backgroundColor: colors.menuActive} : {backgroundColor: "transparent"}} className="dropdown-btn">
                                    <div className="dropdown-text">
                                        <img src="/icons/binance-icon.svg" alt="network logo"></img>
                                        <p>BSC Testnet</p>
                                        { isdropActive === 1 ? (<section>
                                            <FontAwesomeIcon style={{color: "green"}} icon={faCircle}/>
                                        </section>) : "" }
                                    </div>
                                    { isdropActive === 1 ? (<a href="#">BNB Explorer</a>) : '' }
                                </div>
                            </div>
                        </div>
                    </div>
                    {localStorage.getItem("__account") === null ? (
                        <button style={{
                            backgroundColor: colors.lightBlack,
                            color: colors.text,
                            borderColor: colors.lightBlack
                            }} onClick={loginAction} className="connect-wal">
                            CONNECT WALLET
                            <img src="/icons/metamask-icon.svg" alt="metamask logo"></img>
                        </button>
                    ) : (
                        <button style={{
                            backgroundColor: colors.lightBlack,
                            color: colors.text,
                            borderColor: colors.lightBlack
                            }} onClick={loginAction} className="connect-wal">
                            <span style={{whiteSpace: "nowrap", textOverflow: "ellipsis", width: "163px", overflow: "hidden"}}>{localStorage.getItem("__account")}</span>
                            <img src="/icons/metamask-icon.svg" alt="metamask logo"></img>
                        </button>
                    )}
                </div>
                <div className="day-night">
                    <FontAwesomeIcon style={{color: colors.text}} icon={faMoon}/>
                    <input onClick={themeModificator} style={{backgroundColor: colors.darkGrey}} className="apple-switch" type="checkbox"></input>
                    <FontAwesomeIcon style={{color: colors.text}} icon={faSun}/>
                </div>
            </div>
        </div>
    )
}