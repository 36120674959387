import { getPoolList, getTokenList } from "./ListController"
import { getFarmById } from "./FarmController"

let timeOut: any = null

export const scheduleScriptLoading = async () => {
    await getTokenList()
    await getPoolList()
    await getFarmById().then(() => {
        timeOut = setInterval(async () => {
            await getTokenList()
            await getPoolList()
            await getFarmById()
        }, 360000)
    })
}

export const rerunSchedule = () => {
    timeOut = null
    scheduleScriptLoading()
}