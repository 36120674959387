import { ChangeEvent, useCallback, useRef, useState } from "react"
import { getERC20Contract, getContractAddress } from "../../../eth/ContractController"
import { depositTokensInFarm } from "../../../eth/FarmController"
//import { rerunSchedule } from "../../../eth/NativeScheduler"
import { provider } from "../../../eth"
import Web3 from "web3"

import "./AddingFarm.styles.css"

type props = {
    colors: any
    poolName: string
    stateEvent: number
    setStateEvent: React.Dispatch<React.SetStateAction<number>>
    poolAddress: string
    farmId: string
    poolUserBalance: string
}

export const AddingFarm = ({colors, poolName, stateEvent, setStateEvent, poolAddress, farmId, poolUserBalance}: props) => {
    const web3 = new Web3(provider)
    const tokenBalance = web3.utils.fromWei(poolUserBalance).split('.').length === 1 ? web3.utils.fromWei(poolUserBalance) : (web3.utils.fromWei(poolUserBalance).split('.')[0])
    
    const inputRef = useRef<HTMLInputElement>(null)
    const [inputValue, setInputValue] = useState<string>("")

    const checkTokenInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.currentTarget!.value
        if (Number(inputValue) < 0) {
            e.currentTarget!.value = "0"
        }

        if (Number(inputValue) > Number(web3.utils.fromWei(poolUserBalance))) {
            e.currentTarget!.value = web3.utils.fromWei(poolUserBalance)
        }

        if (inputValue.indexOf(".") !== -1) {
            if (inputValue.split(".")[1].length > 18) {
                e.currentTarget!.value = inputValue.split(".")[0] + "." + inputValue.split(".")[1].slice(0, -1)
            }
        }
        setInputValue(e.currentTarget!.value)
    }, [])

    const setMax = useCallback(() => {
        inputRef.current!.value = web3.utils.fromWei(poolUserBalance)
        setInputValue(web3.utils.fromWei(poolUserBalance))
    }, [inputRef, poolUserBalance])

    const addTokensToFarm = async () => {
        if (inputValue.length === 0) {
            return alert("You must input amount of tokens for farming")
        }

        setStateEvent(2)

        let errors: boolean = false
        const userAccountAddress = String(localStorage.getItem("__account"))

        try {
            const pairToken = await getERC20Contract(poolAddress)
            const farmAddress = await getContractAddress("LOVELYFarm")
            await pairToken.approve(farmAddress, web3.utils.toWei(inputValue)).send({from: userAccountAddress})
            errors = false
        } catch (e) {
            alert("We cant get approve for manipulations with your tokens. Try again...")
            console.log(e)
            errors = true
            setStateEvent(1)
        }

        if (!errors) {
            try {
                await depositTokensInFarm(farmId, web3.utils.toWei(inputValue), String(localStorage.getItem("__account")))
                errors = false
            } catch (e) {
                alert("We cant deposit your tokens in farm. Try again...")
                console.log(e)
                errors = true
                setStateEvent(1)
            }
        }
        
        if (!errors) {setStateEvent(3); setTimeout(() => {window.location.reload()}, 1500)}
    }
    
    return (
        <div className="adding-farm-container">
            <h3 style={{color: colors.text}}><span>YOU CAN FARM</span> {tokenBalance} <span>MORE</span> {poolName}</h3>
            <input
                ref={inputRef}
                onChange={(e) => {checkTokenInput(e)}}
                style={{color: colors.text, backgroundColor: colors.neutralBlack}}
                placeholder="0.0"
                type={"numder"}
            />
            <div className="adding-farm-manipulating">
                <button onClick={setMax} style={{color: colors.text}} className="max">MAX</button>
                <button onClick={addTokensToFarm} className="farm-btn">FARM</button>
            </div>
        </div>
    )
}