import { useNavigate } from "react-router"
import "./PoolItem.styles.css"

type props = {
    colors: any
    poolItem: any
}

export const PoolItem = ({colors, poolItem}: props) => {
    const navigate = useNavigate()
    return (
        <div className='pool-list-container'>
            {poolItem.amount === "0" ? "" : (
                <div className="pool-farm-container">
                    <span style={{color: colors.text}}>Your: {poolItem.amount} {poolItem.name}</span>
                </div>
            )}
            <div className="pool-info">
                <div className="pool-info-box">
                    <div className="pool-info-logos">
                        <img src={poolItem.imgA}></img>
                        <img className="info-logos-b" src={poolItem.imgB}></img>
                    </div>
                    <div className="pool-info-heading">
                        <h3>{poolItem.name}</h3>
                        <p>LIQUIDITY POOL</p>
                    </div>
                </div>
                <div className="pool-info-manipulating">
                    {poolItem.amount !== "0" ? (
                        <div className="pool-info-m-cont">
                            {/*<button className="farm" onClick={() => {navigate(`/farm?pool=${poolItem.token}&tokenA=${poolItem.tokenA}&tokenB=${poolItem.tokenB}`)}}>
                                <img src="/icons/tractor_icon.svg" alt="icon" />FARM
                            </button>*/}
                            <button onClick={() => {navigate(`/pools/remove_liquidity?pool=${poolItem.token}&tokenA=${poolItem.tokenA}&tokenB=${poolItem.tokenB}`)}}>REMOVE</button>
                        </div>
                    ) : ""}
                    <button onClick={() => {navigate(`/pools/liquidity?tokenA=${poolItem.tokenA}&tokenB=${poolItem.tokenB}`)}}>ADD</button>
                </div>
            </div>
        </div>
    )
}