import Web3 from "web3"
import { provider } from "."
import { getName, getSymbol, getContract } from "./ContractController"
import { getUserLiquidityAmount } from "./PoolController"

const web3 = new Web3(provider)

//storage
export let isTokenListLoading: boolean = false
export let tokensList: any[] = []
export let isPoolListLoading: boolean = false
export let poolList: any[] = []
export let isFarmListLoading: boolean = false
export let farmList: any[] = []

export const getTokenList = async (): Promise<void> => {
    isTokenListLoading = true
    isPoolListLoading = true
    isFarmListLoading = true

    const contract = await getContract("LOVELYTokenList")
    let i = 0
    let finish = false
    let items: [object] | any = [];
    do {
        let token: string
        let validated: string
        let name: string
        let symbol: string

        //get token from contract
        try {
            token = await contract.at(i).call()
        } catch (e) {
            console.log(e)
            finish = true
            break
        }

        //is validated?
        try {
            validated = await contract.validated(token).call()
        } catch (e) {
            console.log(e)
            finish = true
            break
        }

        //get name from ERC20
        try {
            name = await getName(token)
        } catch (e) {
            console.log(e)
            name = ""
        }

        //get symbol from ERC20
        try {
            symbol = await getSymbol(token)
        } catch (e) {
            console.log(e)
            symbol = ""
        }

        //push in token storage
        items.push({
            id: i,
            token: token,
            valid: validated,
            name: name,
            symbol: symbol,
            img: "/icons/binance-icon.svg"
        })
        i++
    //if we have error finish
    } while (!finish)

    //set token in storage
    tokensList = items
    isTokenListLoading = false
    //return await items
}

export const getPoolList = async (): Promise<void> => {
    const tokenList = tokensList
    //get token list from function upper
    const factory = await getContract("LOVELYFactory")
    const poolTokens: any[] = []
    const poolTokensTimless: any[] = []
    let id: number = 0

    for (let i = 0; i < tokenList.length; i++) {
        for (let j = 0; j < tokenList.length; j++) {

            const tokenA = tokenList[i].token
            const tokenB = tokenList[j].token
            
            let poolName: string = ""
            
            //get token pair
            const poolToken: string = await factory.getPair(tokenA, tokenB).call()

            if (poolToken.slice(2, poolToken.length) !== "0000000000000000000000000000000000000000" && poolTokensTimless.indexOf(poolToken) === -1) {
                
                poolName = `${tokenList[i].symbol}-${tokenList[j].symbol}`
                let liquidityAmount = await getUserLiquidityAmount(poolToken, String(localStorage.getItem("__account")))
                liquidityAmount = web3.utils.fromWei(liquidityAmount)

                poolTokens.push({
                    id: id,
                    token: poolToken,
                    amount: liquidityAmount,
                    tokenA: tokenA,
                    tokenB: tokenB,
                    imgA: tokenList[i].img,
                    imgB: tokenList[j].img,
                    name: poolName
                })
                poolTokensTimless.push(poolToken)
                id++
            }
        }
    }

    poolList = poolTokens
    isPoolListLoading = false
    //return await poolTokens
}

export const setFarmList = (list: any[]) => {
    farmList = list
    isFarmListLoading = false
}